.skin-#{$skin-name} {

	.dropdown,
	.dropdown-like {
		/* Moved into root
		--dropdown-border-color       : var(--default-border-color);
		--dropdown-bg                 : #{$white};
		*/
		&-menu-item {
			--dropdown-item-bg        : transparent;
			--dropdown-item-color     : inherit;

			&.is-selected {
				--dropdown-item-bg    : var(--primary-color);
				--dropdown-item-color : #{$white};
			}

			&:hover, &:focus, &.is-focus {
				--dropdown-item-bg    : #{rgba($primary-color, .125)};
				--dropdown-item-color : var(--primary-color);
			}

			&-icon {
				--dropdown-item-icon-bg : #{rgba($lightgrey, .5)};
			}
		}
	}

	.popin {
		--popin-bg                    : var(--default-bg);
		--popin-footer-bg             : #{rgba($theme-3, .75)};
	}

	.control-bar {
		--control-bar-bg              : #{rgba($theme-3, .75)}; // --default-bg
	}

	.patient-header {
		--patient-header-bg           : #{rgba($theme-3, .9)}; // --default-bg
	}

	.timetable {
		--timetable-alpha-bg          : #{rgba($theme-3, .625)}; // --default-bg
	}

	.event-header {
		--event-header-bg             : #{rgba($theme-3, .9)}; // --default-bg
	}

	.ticket-header {
		--ticket-header-bg            : #{rgba($theme-3, .9)}; // --default-bg
	}

	.aside-navigation a,
	.aside-navigation button {
		--aside-nav-bg                : none;
		--aside-nav-color             : inherit;

		&:hover, &:focus {
			--aside-nav-bg            : #{$theme-2};
			--aside-nav-color         : var(--primary-color);
		}

		&.router-link-exact-active {
			--aside-nav-bg            : none;
			--aside-nav-color         : var(--primary-color);
		}
	}

	.misc-item {
		--misc-item-icon-bg           : #{lighten($lightgrey, 7.5%)};
		--misc-item-hover-color       : var(--primary-color);
		--misc-item-active-color      : #{$white};
		--misc-item-active-bg         : var(--primary-color);
		--misc-item-active-icon-bg    : #{darken($primary-color, 10%)};
		--misc-item-active-icon-color : #{$white};
	}

	.data-table {
		--line-hover-bg               : #{rgba($primary-color, .125)};
		--line-selected-bg            : #{rgba($primary-color, .125)};
		--tooltip-bg                  : #{$darkgrey};
		--tooltip-color               : #{$white};
	}

	.draggable {
		--draggable-border-color      : var(--default-border-color);
		--draggable-bg                : #{darken($theme-3, 5%)}; // --default-bg
	}

	.shop-option {
		--bg-focus                    : #{$theme-2};
	}

}
