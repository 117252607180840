.shop {

	&-grid {
		margin: 10% 0;
	}

	&-option {
		display: flex; padding: 1em;
		flex-flow: column nowrap; justify-content: flex-start; align-items: stretch;
		border: 1px solid var(--default-border-color); border-radius: var(--global-border-radius);

		&.is-main {
			background-color: var(--bg-focus);
		}

		* {
			margin-top: 0;

			&:last-child {
				margin-top: auto;
			}
		}
	}
}

.shop-success-icon {
	font-size: 8em;
}

.check-container,
.transfer-container {
	margin-left: auto; margin-right: auto; padding: 1em;
	border: 1px solid var(--default-border-color);

	& > div {
		display: inline-block;
		text-align: left;
	}
}

.check-container {
	max-width: 15em;
}

.transfer-container {
	max-width: 25em;
}
