.person-item {
	border: 1px solid var(--input-border-color); border-radius: var(--global-border-radius);
	background-color: var(--input-bg);

	& > * {
		padding: .5em;
	}

	&-header {
		display: flex; width: 100%; box-sizing: border-box;
		flex-flow: row nowrap; justify-content: flex-start; align-items: flex-start;

		a {
			display: block;
		}
	}

	&-avatar {
		width: 1em; height: 1em; overflow: hidden;
		flex: 0 0 1em;
		border-radius: var(--global-border-radius);
		background-color: var(--default-bg);
		font-size: #{$default-line-height * 2em};
	}

	&-name {
		margin-left: .5em;
		flex: 1 1 auto;
		word-break: break-word;
	}

	&-border {
		border-top: 1px solid var(--input-border-color);
	}
}
