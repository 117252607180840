@each $breakpoint, $value in $breakpoints {
	.rwd-under-#{$breakpoint} {
		@include media(">#{$breakpoint}") {
			display: none;
		}
	}

	.rwd-over-#{$breakpoint} {
		@include media("<=#{$breakpoint}") {
			display: none;
		}
	}
}

.rwd-break-over-large {
	@include media("<=large") {
		br {
			display: none;
		}
	}
}
