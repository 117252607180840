.loading-spinner {
	--duration: 3s;
	height: 1em;
	pointer-events: none;
	animation: var(--duration) infinite easing(easeInOutQuart) spinner-loader;

	circle {
		transform-origin: 45px 45px;
		animation: var(--duration) infinite cubic-bezier(0.3, 0, 0.5, 1) spinner-circle;

		@for $i from 1 through 4 {
			&:nth-child(#{$i}) {
				animation-delay: #{135 * ($i - 1)}ms;
			}
		}
	}
}
