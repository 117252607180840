.skin-#{$skin-name} {

	--default-color                  : #{$default-color};
	--primary-color                  : #{$primary-color};
	--info-color                     : #{$info-color};
	--success-color                  : #{$success-color};
	--warning-color                  : #{$warning-color};
	--danger-color                   : #{$danger-color};

	--body-bg                        : #{darken($theme-3, 10%)};
	--default-bg                     : #{$theme-3};
	--default-backdrop-bg            : #{rgba($black, .75)};
	--default-border-color           : #{$theme-1};
	--default-box-shadow             : #{rgba($black, .25)};

	--text-color                     : #{$text-color};
	--link-color                     : var(--primary-color);
	--title-color                    : var(--primary-color);

	--selection-color                : #{$white};
	--selection-bg                   : var(--primary-color);

	--scrollbar-thumb                : #{rgba($black, .15)};
	--scrollbar-thumb-hover          : #{rgba($black, .3)};
	--scrollbar-thumb-active         : #{rgba($black, .45)};
	--scrollbar-track                : transparent;

	--hr-bg                          : var(--default-border-color);

	--label-color                    : var(--text-color);
	--label-focus-color              : var(--primary-color);

	--input-color                    : var(--text-color);
	--input-placeholder-color        : #{rgba($text-color, .4)};
	--input-border-color             : var(--default-border-color);
	--input-shadow-color             : transparent;
	--input-bg                       : #{$theme-3};
	--select-caret-color             : inherit;

	--input-focus-color              : var(--input-color);
	--input-focus-placeholder-color  : #{rgba($text-color, .2)};
	--input-focus-border-color       : var(--primary-color);
	--input-focus-shadow-color       : #{rgba($primary-color, .5)};
	--input-focus-bg                 : var(--input-bg);

	--input-selected-color           : #{$white};
	--input-selected-border-color    : #{darken($primary-color, 10%)};
	--input-selected-bg              : var(--primary-color);
	--input-selected-focus-bg        : #{darken($primary-color, 5%)};

	--togglebox-hover-bg             : #{rgba($primary-color, .125)};
	--togglebox-hover-color          : var(--primary-color);
	--togglebox-active-bg            : var(--primary-color);
	--togglebox-active-color         : #{$white};

	--switchbox-bullet-bg            : #{$white};
	--switchbox-checked-bullet-bg    : #{$white};
	--switchbox-checked-color        : #{$white};

	--input-alert-shadow-color       : transparent;
	--input-alert-focus-shadow-color : #{rgba($danger-color, .25)};

	--alert-feedback-bg              : #{rgba($danger-color, .125)};

	--dropdown-border-color          : var(--default-border-color);
	--dropdown-bg                    : #{$white};

	// Tested for colorblindness
	@each $id, $color in (
		1: #4a9,
		2: #ba5,
		3: #c67,
		4: #a49,
		5: #936
	) {
		--eva-level-#{$id}: #{$color};
	}

	@each $id, $color in (
		1: #d1f2a5,
		2: #effab4,
		3: #ffc48c,
		4: #ff9f80,
		5: #f56991
	) {
		--walton-level-#{$id}: #{$color};
	}

	@each $id, $color in (
		1: #328,
		2: #68a,
		3: #4a9,
		4: #ba5,
		5: #c67,
		6: #a49,
		7: #936
	) {
		--imc-level-#{$id}: #{$color};
	}
}
