.vs__dropdown-toggle {
	padding: .5em .75em .75em 1em;
	box-shadow: 0 0 0 .125em var(--input-shadow-color);
	background: var(--input-bg);
	transition: $form-duration $default-timing-function;

	&:hover {
		border-color: var(--input-focus-border-color);
		color: var(--input-focus-border-color);

		.vs__open-indicator {
			color: var(--input-focus-border-color);
		}
	}
}

.vs--open .vs__dropdown-toggle {
	border-color: var(--input-focus-border-color); box-shadow: 0 0 0 .125em var(--input-focus-shadow-color);
}

.vs__search {
	&,
	&:hover,
	&:focus {
		min-width: 2.5em; margin: .5em .25em .25em 0; padding: 0;
		border-color: transparent;
	}
}

.vs__selected-options {
	padding: 0;
}

.vs__selected {
	margin: .25em .25em 0 0; padding: .25em .25em .25em .5em;
	color: var(--vs-selected-color);
}

.vs--single .vs__selected {
	color: inherit;
}

.vs__deselect,
.vs__clear {
	margin-left: .25em; padding: .25em 0 0 .25em;
	color: inherit;
	fill: currentColor;
}

.vs__actions {
	margin: .5em 0 0 0; padding: 0;
}

.vs__open-indicator {
	color: $vs-controls-color;
	fill: currentColor;
}

.vs--open .vs__open-indicator {
	transform: rotate(-180deg) scale($open-indicator-size);
	color: var(--input-focus-border-color);
}

.vs__dropdown-menu {
	padding: 0;
}

.vs__dropdown-option,
.vs__no-options {
	line-height: $vs-component-line-height; color: var(--vs-dropdown-option-color);
	cursor: pointer; transition: $default-duration $default-timing-function;

	@include media('<=medium') {
		padding: 1em;
	}

	@include media('>medium') {
		padding: .5em 1em;
	}
}

.vs__no-options {
	text-align: left; font-style: italic;
	opacity: .5; pointer-events: none;
}

.vs__dropdown-option--highlight {
	background: $vs-state-active-bg;
	color: $vs-state-active-color;
}

.fc-event {
	overflow: hidden;
}
