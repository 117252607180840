.accounting {
	&-filter-button {
		@include media('<=small') {
			.is-for-more {
				display: none;
			}
			&.is-mobile-closed {
				.is-for-more {
					display: initial;
				}
				.is-for-less {
					display: none;
				}
			}
		}
		@include media('>small') {
			.is-for-less {
				display: none;
			}
			&.is-open {
				.is-for-less {
					display: initial;
				}
				.is-for-more {
					display: none;
				}
			}
		}
	}

	&-no-result {
		margin: 20% 0 0;
	}

	&-table {
		@include media('>small') {
			.is-spend { // tr
				opacity: .5;

				&:hover {
					opacity: 1;
				}
			}

			&-action {
				opacity: .25;

				&:hover,
				&:focus {
					opacity: 1;
				}
			}

			.is-spend &-action {
				opacity: .5;
			}

			tr:hover &-action {
				opacity: 1;
			}
		}

		@include media('<=small') {
			thead {
				display: none;
			}

			&,
			tbody {
				display: block;
			}

			tr {
				display: flex; padding: .5em 0;
				flex-flow: row wrap; justify-content: center; align-items: baseline;
				border-top: 1px solid var(--default-border-color);
			}

			td {
				padding: .25em .5em; box-sizing: border-box;
			}

			& td:not(&-checkbox) {
				padding-left: 2em;
			}

			& td:not(&-button) {
				flex: 1 0 100%;
			}

			&-label {
				font-weight: var(--font-weight-bold);
			}

			.text-ellipsis {
				max-width: initial; min-width: initial;
			}

			&-num {
				display: flex;
				flex-flow: row wrap; justify-content: space-between; align-items: baseline;

				&::after {
					content: '';
					height: 0; margin: 0 .5em;
					flex: 1 0 auto; order: 1;
					border-bottom: 1px dotted var(--default-border-color);
				}

				&-value {
					margin-left: auto;
					order: 2;
				}
			}

			&-button {
				margin-top: .5em;
			}
		}
	}

	&-footer {
		padding: 1em 0; margin: -1em 0;
		background-color: var(--default-bg);

		&-hr {
			margin: 1em 0;
		}
	}

	&-context {
		.collapsible-panel-content {
			padding-left: .125em;
		}

		.checkbox {
			font-size: .75em;
		}
	}

	&-search {
		&-input {
			position: relative;

			&:first-child {
				border-bottom-left-radius: 0; border-bottom-right-radius: 0;
			}
			&:last-child {
				margin-top: -1px;
				border-top-left-radius: 0; border-top-right-radius: 0;
			}
			&:hover {
				z-index: 1;
			}
			&:focus {
				z-index: 2;
			}
		}
	}
}
