.apexcharts {
	&-svg {
		width: auto; height: auto;

		text {
			font-family: var(--default-font-family) !important;
			font-style: var(--default-font-style);
			font-weight: var(--default-font-weight);
			fill: currentColor;
		}

		line {
			stroke: var(--default-border-color);
		}
	}

	&-tracks path {
		stroke: var(--track-color);
	}

	&-marker {
		stroke: var(--default-bg);
	}

	&-datalabels-group &-datalabel-value {
		fill: currentColor;
	}

	&-pie-series {
		path {
			stroke: var(--default-bg);
		}
	}

	&-canvas[class*='apexcharts-theme'] &-tooltip:not(#{&}-theme-dark),
	&-canvas &-xaxistooltip,
	&-canvas &-menu {
		border: 1px solid var(--dropdown-border-color); box-shadow: 0 .125em .25em var(--default-box-shadow); border-radius: var(--global-border-radius);
		background-color: var(--dropdown-bg);
		color: currentColor;
		transition: $default-duration $default-timing-function;
	}

	&-canvas[class*='apexcharts-theme'] &-tooltip#{&}-theme-dark {
		box-shadow: 0 .125em .25em var(--default-box-shadow); border-radius: var(--global-border-radius);
		transition: $default-duration $default-timing-function;
	}

	&-canvas[class*='apexcharts-theme'] &-tooltip &-tooltip-title {
		border-bottom-color: var(--dropdown-border-color);
		background: rgba(black, .05);
		font-family: var(--title-font-family) !important;
		font-style: var(--title-font-style) !important;
		font-weight: var(--title-font-weight) !important;
		color: var(--title-color);
	}

	&-canvas[class*='apexcharts-theme'] &-tooltip &-tooltip-text,
	&-canvas &-xaxistooltip-text,
	&-canvas &-menu &-menu-item {
		font-family: var(--default-font-family) !important;
		font-style: var(--default-font-style) !important;
		font-weight: var(--default-font-weight) !important;
	}

	&-canvas &-xaxistooltip {
		&::before {
			border-bottom-color: var(--dropdown-border-color);
		}

		&::after {
			border-bottom-color: var(--dropdown-bg);
		}
	}

	&-canvas &-menu &-menu-item {
		background: none;
		transition: $default-duration $default-timing-function;

		&:hover {
			background: var(--selection-bg);
			color: var(--selection-color);
		}

		&:not(:last-child) {
			border-bottom: 1px solid var(--default-border-color);
		}
	}
}
