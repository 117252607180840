.is-overflow {
	overflow: auto;
}

.is-overflow-x {
	overflow-x: auto;
}

.is-full-height {
	flex: 1 0 auto;
}

.ul-reset {
	@extend %ul-reset;
}

.no-marg {
	margin-top: 0; margin-bottom: 0;
}

.marg-auto {
	margin: auto;
}

.marg-v {
	margin-top: $global-gutters; margin-bottom: $global-gutters;
}

.marg-t {
	margin-top: $global-gutters;
}

.marg-b {
	margin-bottom: $global-gutters;
}

.marg-20 {
	margin-top: 20%; margin-bottom: 20%;
}

.marg-l-1 {
	margin-left: 1em;
}

.pad-v {
	padding-top: $global-gutters; padding-bottom: $global-gutters;
}

.semi-marg-v {
	margin-top: $global-gutters / 2; margin-bottom: $global-gutters / 2;
}

.semi-marg-b {
	margin-bottom: $global-gutters / 2;
}

.semi-pad-v {
	padding-top: $global-gutters / 2; padding-bottom: $global-gutters / 2;
}

.inline-flex {
	display: inline-flex;
}

.childs-space-between,
.childs-space-around {
	display: flex;
	flex-flow: row wrap; align-items: center;

	&.is-align-top {
		align-items: flex-start;
	}

	&.is-nowrap {
		flex-wrap: nowrap;
	}
}

.childs-space-between {
	justify-content: space-between;
}

.childs-space-around {
	justify-content: space-around;
}

.is-pushed-right {
	margin-left: auto;
}

.is-pushed-down {
	margin-top: auto;
}

.is-sticky-top,
.is-sticky-bottom {
	position: sticky; z-index: $z-index-sticky;
}

.is-sticky-top {
	top: 0;
}

.is-sticky-bottom {
	bottom: 0;
}

.is-no-transition {
	transition: none;
}

.select {
	user-select: all;
}

input.is-hidden {
	position: absolute; z-index: -1;
	opacity: 0;
	/* DO NOT USE visibility: hidden; TO KEEP MESSAGE WHEN REQUIRED! */
}
