.super-panel {
	position: fixed; top: 0; right: 0; bottom: 0; left: 0; z-index: $z-index-super-panel;
	display: flex; overflow: hidden;
	flex-flow: column nowrap; justify-content: center; align-items: center;
	background-color: var(--default-backdrop-bg);
	transition: $popin-duration $popin-timing-function;

	&.appear-enter,
	&.appear-leave-to {
		opacity: 0;
	}

	&.appear-enter &-frame,
	&.appear-leave-to &-frame {
		transform: scale(.9);
	}

	@include media('>medium') {
		@supports (filter: blur(5px)) {
			&.appear-leave-to ~ .l-app {
				filter: blur(0);
			}

			& ~ .l-app {
				filter: blur(3px);
			}
		}
	}

	&-frame {
		@extend .scrollbar;
		width: 95%; height: 95%; overflow: auto;
		border-radius: var(--global-border-radius);
		background-color: var(--default-bg);
		transition: inherit;

		@include media('>medium') {
			height: 90%;
		}
	}
}
