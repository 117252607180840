/* ——————————————————————————————————————————————————————————————————————————
   DEFAULT SKIN
   —————————————————————————————————————————————————————————————————————————— */

$skin-name: "default";

@import
	"colors",
	"root",
	"layout",
	"components",
	"variants",
	"vendors";

@import
	"fonts",
	"typo";

@import
	"misc";


// DO NOT DUPLICATE INTO OTHER SKIN
.login-view {
	--back-bg                            : #{$darkgrey};

	--login-nav-color                    : #{$white};
	--login-frame-bg                     : #{rgba($white, .95)};
	--login-frame-title-bg               : #{rgba($theme-4, .95)};
}
