.merge-grid {
	min-width: 100%;
	display: grid;
	grid-template-columns: auto repeat(var(--col-count), minmax(15em, 1fr)) 1px;
	grid-template-rows: repeat(var(--row-count), 1fr);
	align-items: center;
	grid-auto-flow: column;

	& > * {
		padding: .25em 1em;
	}

	&-title {
		position: sticky; left: 0;
		display: flex; padding-left: 0; padding-right: 1.5em;
		flex-flow: row nowrap; justify-content: flex-end; align-items: center;
		align-self: stretch;
		background-image: linear-gradient(to left, transparent 0%, var(--default-bg) 10%);
		font-weight: var(--font-weight-bold); text-align: right;
	}

	&-container {
		position: relative;

		&::after {
			content: '';
			position: absolute; top: 0; right: 0; bottom: 0;
			width: 2em;
			background-image: linear-gradient(to right, transparent, var(--default-bg));
			opacity: 0; pointer-events: none;
			transition: opacity $default-duration $default-timing-function;
		}

		&.is-overflowing::after {
			opacity: 1;
		}
	}

	&-loading {
		border-color: currentColor;
		background-color: currentColor;
		border-radius: var(--global-border-radius);
		opacity: .05;
		animation: loading-cell .5s calc(var(--factor, 0) * 50ms) easing(easeOutSine) infinite alternate;

		.text-center & {
			display: inline-block;
			width: 14em;
		}
	}

	@for $n from 1 to 7 {
		&-title:nth-of-type(#{$n}) &-loading {
			width: #{(random(10) + 5) * 1em};
		}
	}

	@keyframes loading-cell {
		from { opacity: .05; }
		to   { opacity: .2; }
	}

	// Test for horizontal scroll
	&-scroll-test {
		width: 1px; padding: 0;
		grid-row: 1 / -1; align-self: stretch;
	}
}
