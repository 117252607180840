.misc-collapsible {
	height: 0; overflow: hidden;

	&.is-active {
		height: auto;
	}

	&-content {
		transform: translateY(-1em);
		opacity: 0; visibility: hidden; pointer-events: none;
		transition: $default-duration $default-timing-function;

		& > *:first-child {
			margin-top: 0;
		}

		& > *:last-child {
			margin-bottom: 0;
		}
	}

	&.is-active &-content {
		transform: translateY(0);
		opacity: 1; visibility: visible; pointer-events: auto;
	}
}
