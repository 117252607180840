/* ==========================================================================
   CONFIG
   ========================================================================== */

// Layout

$wrapper-width                : 96rem;
$global-gutters               : 2rem;


// Responsive

$breakpoints                  : (
'small'                       : 480px,
'medium'                      : 768px,
'large'                       : 960px,
'xlarge'                      : 1280px,
'xxlarge'                     : 1440px,
'huge'                        : 2560px
);

$media-ratio                  : (
'xsmall'                      : 1.2,
'small'                       : 1.3,
'medium'                      : 1.4,
'large'                       : 1.5,
'xlarge'                      : 1.6,
'xxlarge'                     : 1.6,
'huge'                        : 1.8
);


// Z-indexes

$z-index-notification         : 70;
$z-index-popin                : 60;
$z-index-super-panel          : 50;
$z-index-navigation           : 40;
$z-index-dropdown             : 30;
$z-index-aside                : 20;
$z-index-context              : 10;
$z-index-sticky               : 5;


// Scrollbars

$scrollbar-size               : .5rem;
$scrollbar-margin             : .25rem;


// Typo

$default-font-size            : 1rem;
$default-line-height          : 1.4;

$font-sizes                   : ((
'class'                       : 'xl',
'size'                        : 2em
),(
'class'                       : 'lg',
'size'                        : 1.5em
),(
'class'                       : 'md',
'size'                        : 1.25em
),(
'class'                       : 'rg',
'size'                        : 1em
),(
'class'                       : 'sm',
'size'                        : .875em
),(
'class'                       : 'xs',
'size'                        : .75em
));


// Transitions

$default-duration             : .15s;
$default-timing-function      : easing(easeInOutQuart);

$popin-duration               : .5s;
$popin-timing-function        : easing(easeOutQuart);

$form-duration                : .25s;
$panel-duration               : .4s;
