.tox {
	&#{&}-tinymce {
		border-color: var(--input-border-color);
	}

	svg {
		width: initial; height: initial;
	}

	&#{&} &-edit-area {
		background-color: white;
	}

	.is-page & &-sidebar-wrap {
		padding: 1em;
		overflow: auto;
	}

	.is-page & &-edit-area {
		width: 21cm; height: auto; margin: 0 auto; overflow: initial;
		flex: 0 0 21cm;
		border: 1px solid var(--default-border-color); box-shadow: 0 .125em .25em rgba(black, .1);
	}
}
