/*
	draggable for state selecting
	draggable-frame for applying style, not necessarily on main container
	draggable-frame.is-with-border in case of container with already a border style
	draggable-handler for handler
*/

.draggable {
	&.sortable-ghost * {
		visibility: hidden;
		transition: none;
	}
	&#{&}-frame,
	& &-frame {
		transition: background-color .25s easing(easeOutQuart);
	}

	&.sortable-ghost#{&}-frame,
	&.sortable-ghost &-frame {
		box-shadow: inset 0 0 0 1px var(--draggable-border-color); border-radius: var(--global-border-radius);
		background-color: var(--draggable-bg);
		visibility: visible;

		&.is-with-border {
			border-color: var(--draggable-border-color); box-shadow: none;
		}
	}

	&.sortable-ghost#{&}-frame &-frame,
	&.sortable-ghost &-frame &-frame {
		visibility: hidden; // Override in case of nested draggable-frames
	}

	&-handler {
		color: var(--default-color);
		cursor: grab;

		&:hover {
			color: var(--primary-color);
		}

		&:active {
			cursor: grabbing;
		}
	}
}
