@font-face {
	font-family: 'Roboto Slab';
	font-style: normal;
	font-weight: 400;
	font-display: swap;
	src: local('Roboto Slab Regular'),
	     local('RobotoSlab-Regular'),
	     url('../../../fonts/roboto-slab-regular.woff2') format('woff2');
}

@font-face {
	font-family: 'Open Sans';
	font-style: normal;
	font-weight: 400;
	font-display: swap;
	src: local('Open Sans Regular'),
	     local('OpenSans-Regular'),
	     url('../../../fonts/open-sans-regular.woff2') format('woff2');
}

@font-face {
	font-family: 'Open Sans';
	font-style: normal;
	font-weight: 600;
	font-display: swap;
	src: local('Open Sans SemiBold'),
	     local('OpenSans-SemiBold'),
	     url('../../../fonts/open-sans-semibold.woff2') format('woff2');
}
