.data-table {
	width: 100%;
	border-collapse: collapse;

	thead {
		border-bottom: 1px solid var(--default-border-color);
	}

	tbody tr {
		&:hover {
			background-color: var(--line-hover-bg);
		}

		&.is-selected {
			background-color: var(--line-selected-bg);
		}
	}

	th,
	td {
		padding: .25em .5em;
	}

	&-icon {
		@include media('>small') {
			position: relative;
			cursor: help;

			&-tooltip {
				position: absolute; bottom: 100%; left: 50%;
				padding: .5em 1em;
				transform: translate(-50%, 0) scale(.8);
				border-radius: var(--global-border-radius);
				background-color: var(--tooltip-bg);
				font-size: .75em; white-space: nowrap; color: var(--tooltip-color); text-align: center;
				opacity: 0; visibility: hidden; pointer-events: none;
				transition: inherit;

				&::after {
					content: '';
					position: absolute; bottom: -.25em; left: 50%; z-index: -1;
					width: .5em; height: .5em;
					transform: translateX(-50%) rotate(45deg);
					background-color: var(--tooltip-bg);
				}
			}

			&:hover &-tooltip,
			&:focus &-tooltip {
				transform: translate(-50%, -.5em) scale(1);
				opacity: 1; visibility: visible;
			}
		}
	}

	.text-numeric {
		user-select: all;
	}

	.text-ellipsis {
		max-width: 0; min-width: 7em;
	}

	@for $i from 1 through 9 {
		.w#{$i * 10} {
			width: #{$i * 10%};
		}
	}
}
