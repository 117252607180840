.l-header {
	flex-flow: row nowrap; justify-content: space-between; align-items: center;
	border-bottom: var(--header-border);
	background-color: var(--header-bg);

	&,
	a {
		color: var(--header-color);
	}
}

.btn-in-header {
	@extend .btn--reset;
	padding: .5em;
	font-size: 1.5em;

	&:hover,
	&:focus {
		background-color: var(--header-btn-hover-bg);
		color: var(--header-btn-hover-color);
	}

	&.is-for-nav.is-open,
	&.is-for-aside:not(.is-open),
	&.is-for-context.is-mobile-closed {
		background-color: var(--header-btn-active-bg);
		color: var(--header-btn-active-color);
	}

	&.is-for-nav,
	&.is-for-aside {
		&::before {
			position: fixed; top: 0; right: 0; bottom: 0; left: 0;
			background-color: var(--header-nav-backdrop);
			opacity: 0; visibility: hidden; pointer-events: none; content: '';
			transition: inherit; transition-duration: $panel-duration;
		}

		&.is-open::before {
			opacity: 1; visibility: visible; pointer-events: auto;
		}
	}

	&.is-for-nav {
		&::before {
			z-index: $z-index-navigation - 1;
		}
	}

	&.is-for-aside {
		.is-without-aside & {
			z-index: -1;
			// No 'display: none' to keep space
			opacity: 0; visibility: hidden; pointer-events: none;

			&::before {
				content: none;
			}
		}

		&::before {
			z-index: $z-index-aside - 1;
		}

		&.is-open {
			svg {
				transform: rotate(-180deg);
			}
		}
	}

	&.is-for-context.is-mobile-closed {
		svg {
			transform: rotate(180deg);
		}
	}

	svg {
		transition: inherit;
	}

	&.is-white-space {
		z-index: -1;
		// No 'display: none' to keep space
		opacity: 0; visibility: hidden; pointer-events: none;
	}
}
