html {
	font-size: calc(.625em * var(--zoom-ratio, 1));

	&.zoom-tiny {
		--zoom-ratio: .8;
	}

	&.zoom-small {
		--zoom-ratio: .9;
	}

	&.zoom-large {
		--zoom-ratio: 1.1;
	}

	&.zoom-huge {
		--zoom-ratio: 1.2;
	}
}

body {
	@include rwd-rem();

	font-family: var(--default-font-family);
	font-style: var(--default-font-style);
	font-weight: var(--default-font-weight);
	line-height: $default-line-height;
	color: var(--text-color);
	// transition: color $panel-duration $default-timing-function; // For skin switching
}

h1, h2, h3, h4, h5, h6, .h-like {
	font-family: var(--title-font-family); font-style: var(--title-font-style); font-weight: var(--title-font-weight); color: var(--title-color);
}

h1 {

}
h2 {
	font-size: 1.5em;
}
h3 {
	font-size: 1.25em;
}

strong, b, th, .text-strong {
	font-weight: var(--font-weight-bold);
}

.h-like, p, .p-like, hr, ul, ol, dl, blockquote, pre, caption {
	margin: $default-line-height * 1em 0;
}

a, .btn--link-like {
	outline: 1px dotted transparent;
	color: var(--link-color); text-decoration: underline;

	&:hover,
	&:focus {
		text-decoration: none;
	}

	&:focus {
		outline-color: var(--default-color);
	}

	img, svg {
		text-decoration: none;
	}
}


// Typo helpers ---------------------------------------------------------------


// Resets

.h-reset,
.text-reset {
	font: inherit; color: inherit;
}
.h-reset {
	margin: initial; padding: 0;
}


// Micro typo

@each $sizes in $font-sizes {
	$class: map-get($sizes, 'class');
	$size: map-get($sizes, 'size');

	.text-#{$class} {
		font-size: $size;
	}
}
.text-left {
	text-align: left;
}
.text-center {
	text-align: center;
}
.text-right {
	text-align: right;
}
.text-middle {
	vertical-align: middle;
}
.text-uppercase {
	text-transform: uppercase;
}
.text-uppercase-first-letter::first-letter {
	text-transform: uppercase;
}
.text-capitalize {
	text-transform: capitalize;
}
.text-lowercase {
	text-transform: lowercase;
}
.text-normal {
	font-weight: var(--font-weight-normal);
}
.text-bold {
	font-weight: var(--font-weight-bold);
}
.text-italic {
	font-style: italic;
}
.text-numeric {
	font-family: 'Roboto Mono', monospace;
}
.text-handwriting {
	font-family: 'Permanent Marker', cursive;
}
.text-strikethrough {
	text-decoration: line-through;
}

// Overflows helpers

.text-nowrap {
	white-space: nowrap;
}
.text-ellipsis {
	overflow: hidden;
	white-space: nowrap; text-overflow: ellipsis;
}
.text-wrap {
	word-break: break-word; hyphens: auto;
}
.text-break {
	word-break: break-all; hyphens: none;
}
.text-pre-line {
	white-space: pre-line;
}


// Colors

.text-inherit {
	color: inherit;
}

@each $variant in (primary, default, info, success, warning, danger) {
	.text-#{$variant} {
		color: var(--#{$variant}-color);
	}
}

.text-placeholder {
	color: var(--input-placeholder-color);
}
