.search-filter {

	&-content {
		height: 0; overflow: hidden;
	}

	&-list {
		@extend %ul-reset;
		margin-top: .5em; padding: 1em .5em;
		transform: translateY(-1em);
		border: 1px solid var(--search-filter-border-color); border-radius: var(--global-border-radius);
		background: var(--search-filter-bg);
		opacity: 0; visibility: hidden; pointer-events: none;
		transition: $default-duration $default-timing-function;

		li {
			display: inline;
		}

		&-item {
			position: relative;

			input {
				@extend %hide-input;
			}

			label {
				padding: .0625em .125em;
				border: 1px solid transparent; border-radius: calc(var(--global-border-radius) / 2);
				cursor: pointer;
				transition: $default-duration $default-timing-function;
			}

			label:hover,
			input:focus + label {
				border-color: inherit;
				color: var(--search-filter-item-color);
			}

			input:not(:checked) + label {
				text-decoration: line-through;
			}
		}
	}

	&.is-active &-content {
		height: auto;
	}

	&.is-active &-list {
		transform: translateY(0);
		opacity: 1; visibility: visible; pointer-events: auto;
	}
}
