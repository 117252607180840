$themeColor    : var(--vsl-theme);
$bgColor       : var(--vsl-bg);
$dotShadowFocus: var(--vsl-dot-shadow-focus);
$dotBgColor    : var(--vsl-dot-bg);

.vue-slider-dot {
	width: 1.5em !important;
	height: 1.5em !important;
}

.vue-slider-dot-handle {
	display: flex;
	justify-content: center; align-items: center;
}
