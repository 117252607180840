.letter {
	&-scroll {
		padding: $global-gutters; overflow: auto;
		text-align: center;
	}

	&-container {
		position: relative;
		display: inline-block;

		%container-frame,
		&::before,
		&::after {
			content: '';
			border: 1px solid var(--default-border-color); box-shadow: 0 .075em .125em rgba(black, .1);
			background-color: white;
		}

		&::before,
		&::after {
			position: absolute; top: 0; right: 0; bottom: 0; left: 0; z-index: 0;
		}

		&::before {
			transform: rotate(1.75deg) translate(.25em, .25em);
		}

		&::after {
			transform: rotate(-1.25deg) translate(.5em, -.25em);
		}
	}

	&-frame {
		@extend %container-frame;
		position: relative; z-index: 1;
		text-align: left;
	}
}
