[class*=' notification-'],
[class^='notification-'] {
	position: relative; z-index: $z-index-notification;
	display: block; padding: .75em 2em .75em $global-gutters;
	background-color: var(--notification-bg);
	text-align: center; color: var(--notification-color);
	transition: $default-duration $default-timing-function;

	&.fade-enter,
	&.fade-leave-to {
		transform: translateY(-100%);

		&.is-flash {
			box-shadow: 0 0 0 transparent;
		}
	}

	a:not(.btn),
	.btn--link-like {
		color: inherit; font-weight: var(--font-weight-bold);
	}

	.btn-close {
		position: absolute; top: 50%; right: .25em; z-index: 1;
		transform: translateY(-50%);
	}

	&.is-flash {
		position: absolute; top: 0; left: 0; right: 0;
		box-shadow: 0 0 .25em var(--default-box-shadow);
	}

	&.is-with-progress {
		&::before {
			position: absolute; top: 0; left: 0;
			width: var(--progress); height: .5em;
			background-color: rgba(0, 0, 0, .25);
			content: '';
			transition: width .25s easing(easeOutQuart);
		}
	}
}
