.skeleton-loader {
	display: inline-block;
	width: calc(var(--size, 5) * 1em);
	border-radius: var(--global-border-radius);
	background-color: currentColor;
	opacity: .05; pointer-events: none;
	animation: skeleton-loading .5s calc(var(--factor, 0) * 50ms) easing(easeOutSine) infinite alternate;

	@keyframes skeleton-loading {
		from { opacity: .05; }
		to   { opacity: .2; }
	}

	&::before {
		content: '\00a0';
	}
}
