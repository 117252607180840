.ticket {
	&-frame {
		min-height: 100%; box-sizing: border-box;
		flex: 1 1 auto;
	}

	&-title {
		margin: 0; padding: $global-gutters 0 0;
		border-bottom: 1px solid transparent;
		background-color: var(--ticket-header-bg);
		transition: $default-duration $default-timing-function;

		&.is-narrow {
			position: sticky; top: 0; z-index: $z-index-sticky;
			padding: .5em 0;
			border-bottom-color: var(--hr-bg);
			font-size: 1.25em;
			animation: $panel-duration forwards slide-in;
		}

		&-small {
			font-size: .5em;
		}
	}

	&-separator {
		display: flex;
		flex-flow: row nowrap; justify-content: center; align-items: center;

		&::before,
		&::after {
			@extend hr;
			margin: 0;
			flex: 1 0 auto;
			content: '';
		}

		&-text {
			padding: 0 1em;
			flex: 0 0 auto;
		}
	}

	&-title + &-separator {
		margin-top: 0;
	}

	&-comment {
		display: flex;
		flex-flow: row nowrap; justify-content: flex-start; align-items: flex-start;

		&-avatar {
			width: 1em; height: 1em; margin-right: .25em; overflow: hidden;
			flex: 0 0 1em;
			border-radius: var(--global-border-radius);
			background-color: var(--input-bg);
			font-size: 2.6em;
		}

		&-content {
			flex: 1 1 auto;
		}

		&-text {
			margin: 0;
		}
	}

	&-form {
		display: grid;
		grid-template-columns: 1fr 3em; grid-gap: 1em; grid-auto-flow: row dense; justify-items: stretch; align-items: stretch;

		.input-frame {
			margin: 0;

			textarea {
				display: block;
			}
		}

		&-submit {
			padding: 0;
		}
	}

}
