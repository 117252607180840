.ql-editor {
	background-color: white;
	font-size: 16px;
	line-height: 1.5;

	&, h1, h2, h3, h4, h5, h6 {
		// Font-family stack from GitHub UI
		font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Helvetica,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji;
		color: black;
	}

	h1, h2, h3, h4, h5, h6, strong, b {
		font-weight: bold;
	}

	td {
		padding: 0;
		border: none;
	}
}
