.collapsible-panel {
	&-trigger {
		&-arrow {
			display: inline-block;
			transition: transform $default-duration $default-timing-function;
		}

		&.is-active &-arrow {
			transform: rotate(180deg);
		}
	}

	&-container {
		height: 0; overflow: hidden;

		&.is-active {
			height: auto;
		}
	}

	&-content {
		padding-top: 1em;
		transform: translateY(-1em);
		opacity: 0; visibility: hidden; pointer-events: none;
		transition: $default-duration easing(easeOutSine);
	}

	&-container.is-active &-content {
		transform: translateY(0);
		opacity: 1; visibility: visible; pointer-events: auto;
	}
}
