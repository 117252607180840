.timeline {
	position: relative;
	margin: $global-gutters 0;

	&::after {
		position: absolute; top: .6em; left: 0; bottom: 2.2em; z-index: 1;
		width: .125em;
		background: var(--hr-bg);
		content: '';
	}

	&-section {
		margin: #{$global-gutters * 2} 0 $global-gutters;

		&:first-of-type {
			margin-top: $global-gutters;
		}

		&-title {
			display: inline-block; margin: 0 0 0 #{-$global-gutters}; padding: .125em .25em 0 calc(#{$global-gutters} + .75em);
			background-color: var(--default-bg);
			color: inherit;
		}
	}

	&-item {
		margin: 1em 0 1em 1em;

		&-title {
			position: relative; z-index: 2;
			margin: 0;
			color: inherit;

			&-bullet {
				position: relative;

				&::before {
					position: absolute; top: .2em; left: -.925em;
					font-size: 1.5em; line-height: .5;
					content: '\25CF';
				}
			}

			&-trigger {
				position: relative;

				&-caret {
					position: absolute; top: .1em; left: -1.5em + (.125em / 2) - .075em;
					width: 1em; height: 1em; padding: .075em 0 .075em .125em; // To center weird caret svg -_-v
					border-radius: 50%; box-shadow: 0 0 0 .25em var(--context-bg);
					background-color: var(--color);
					transition: inherit;

					&.is-light {
						color: white;
					}

					&.is-dark {
						color: black;
					}

					&-expand {
						padding: .075em;
						background-color: var(--default-bg);
						color: inherit;
					}
				}
			}
		}

		&-new {
			margin: .5em 0;

			&-trigger-caret {
				transition: transform $default-duration $default-timing-function;
			}
		}

		&.is-active &-title-trigger-caret {
			transform: rotate(90deg);
		}

		&.is-active &-title-trigger-caret-expand {
			transform: rotate(180deg);
		}

		&.is-active &-new-trigger-caret {
			transform: rotate(180deg);
		}

		&-content {
			height: 0; overflow: hidden;

			&-inner {
				margin-bottom: $global-gutters;
				transform: translateY(-1em);
				opacity: 0; visibility: hidden; pointer-events: none;
				transition: $default-duration $default-timing-function;
			}

			p,
			.p-like {
				margin: 1em 0;
			}
		}

		&.is-active &-content {
			height: auto;

			&-inner {
				transform: translateY(0);
				opacity: 1; visibility: visible; pointer-events: auto;
			}
		}
	}
}
