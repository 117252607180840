@import "../../scss/variants";

.skin-#{$skin-name} {

@each $variant, $color in $variant-list {

	.btn-#{$variant} {
		--btn-bg                 : #{$color};
		--btn-border-color       : #{darken($color, 10%)};
		--btn-color              : #{$white};

		&:hover, &:focus, &:active {
			--btn-bg             : #{darken($color, 5%)};
			--btn-border-color   : #{darken($color, 15%)};
			--btn-color          : #{$white};
		}

		&:disabled, &[aria-disabled='true'], &.is-disabled {
			--btn-bg             : #{$darkgrey};
			--btn-border-color   : #{darken($darkgrey, 5%)};
			--btn-color          : var(--text-color);
		}
	}

	.togglebox .is-#{$variant} {
		--input-focus-border-color       : #{$color};
		--input-focus-shadow-color       : #{rgba($color, .5)};

		--togglebox-hover-bg             : #{rgba($color, .125)};
		--togglebox-hover-color          : #{$color};
		--togglebox-active-bg            : #{$color};
		--togglebox-active-color         : #{$white};
	}

	.popin-frame.is-#{$variant} {
		--popin-title-bg         : #{rgba($color, .25)};
		--popin-title-color      : #{$color};
	}

	.notice-#{$variant} {
		--notice-bg              : #{rgba($color, .15)};
		--notice-border-color    : #{rgba($color, .1)};
		--notice-color           : #{$color};
	}

	.notification-#{$variant} {
		--notification-bg        : #{darken($color, 5%)};
		--notification-color     : #{$white};
	}

	.tag-#{$variant} {
		--tag-bg                 : #{rgba($color, .15)};
		--tag-border-color       : #{rgba($color, .1)};
		--tag-color              : #{$color};
	}
}

}
