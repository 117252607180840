%ul-reset {
	margin: 0; padding: 0;
	list-style: none;
}

%hide-input,
.hide-input {
	position: absolute; z-index: -1;
	opacity: 0 !important; // To overide disabled style
	pointer-events: none;
	/* DO NOT USE visibility: hidden; TO KEEP MESSAGE WHEN REQUIRED! */
}
