%grid {
	--size: 1fr;

	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(var(--size), 1fr));
	grid-gap: 0 $global-gutters; grid-auto-flow: row dense; justify-items: stretch; align-items: stretch;

	&.vertical-gap {
		grid-gap: $global-gutters;
	}

	& > * {
		min-width: 0;
	}
}

@mixin grid($from, $to, $width, $scope: '') {
	@for $i from $from through $to {
		$size: ($width - ($global-gutters * $i)) / ($i + 1) + 0.1rem;
		$breakpoint: ($size + ($global-gutters * 2)) / 1rem * 10px; // only when always page full width

		// size is wider than container when navigation appears in small size
		@if $i == 2 {
			$breakpoint: 'medium';
		}

		#{$scope} .grid-#{$i} {
			@extend %grid;

			@include media('>#{$breakpoint}') {
				--size: #{$size};
			}
		}
	}
}

@include grid(1, 3, $wrapper-width, '.wrapper');

.grid-item-full {
	grid-column: 1 / -1;
}
