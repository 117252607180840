.patient {

	&-blacklisted,
	&-deceased {
		display: inline-block; padding: .125em .25em 0;
		border-radius: var(--global-border-radius);
		font-size: .75em; vertical-align: middle;
	}

	&-blacklisted {
		background-color: black;
		color: white;
	}

	&-deceased {
		background-color: var(--danger-color);
		color: white;
	}

	&-header {
		&-wrapper {
			padding: $global-gutters 0; overflow: hidden;
			border-bottom: 1px solid var(--hr-bg);
			background-color: var(--patient-header-bg);
			transition: $default-duration $default-timing-function;
		}

		&-flex {
			display: flex;
			flex-flow: row nowrap; align-items: center;
		}

		&-image {
			width: 1em; height: 1em; margin-right: .25em; overflow: hidden;
			flex: 0 0 1em;
			border-radius: var(--global-border-radius);
			font-size: 6.5em;
			transition: $default-duration $default-timing-function;
		}

		&-infos {
			flex: 1 1 auto;
		}

		&-name {
			margin: 0;
			font-size: 1.5em;
			transition: $default-duration $default-timing-function;
		}

		&-controls {
			margin-top: .5em;
		}

		&-notes {
			height: 0; overflow: hidden;

			&-content {
				transform: translateY(-1em);
				opacity: 0; visibility: hidden; pointer-events: none;
				transition: $default-duration $default-timing-function;
			}

			&.is-active &-content {
				transform: translateY(0);
				opacity: 1; visibility: visible; pointer-events: auto;
			}

			&.is-active {
				height: auto; padding-top: $global-gutters;
			}

			&-trigger {

				&-label-hide {
					display: none;
				}

				&-arrow {
					display: inline-block;
					transition: transform $default-duration $default-timing-function;
				}

				&.is-active &-label-hide {
					display: inline;
				}

				&.is-active &-label-show {
					display: none;
				}

				&.is-active &-arrow {
					transform: rotate(180deg);
				}
			}
		}

		&.is-narrow {
			position: sticky; top: 0; z-index: $z-index-sticky;
			animation: $panel-duration forwards slide-in;

			.patient-header-wrapper {
				padding: $global-gutters/2 0;
			}

			.patient-header-image {
				font-size: 2.75em;
			}

			.patient-header-name {
				font-size: 1em;
			}

			.patient-header-links,
			.patient-header-notes {
				display: none;
			}

		}
	}

	&-popin-print {
		.input-frame {
			display: flex;
			flex-flow: row nowrap;
		}

		.checkbox {
			margin-right: .5em;

			& + label {
				margin-top: .125em;
			}
		}
	}
}
