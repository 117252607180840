/* TimeGridView all-day area
--------------------------------------------------------------------------------------------------*/

.fc-timeGrid-view .fc-day-grid {
	position: relative;
	z-index: 2; /* so the "more.." popover will be over the time grid */
}

.fc-timeGrid-view .fc-day-grid .fc-row {
	min-height: 3em; /* all-day section will never get shorter than this */
}

.fc-timeGrid-view .fc-day-grid .fc-row .fc-content-skeleton {
	padding-bottom: 1em; /* give space underneath events for clicking/selecting days */
}


/* TimeGrid axis running down the side (for both the all-day area and the slot area)
--------------------------------------------------------------------------------------------------*/

  .fc .fc-axis { /* .fc to overcome default cell styles */
	vertical-align: middle;
	padding: 0 4px;
	white-space: nowrap;
}

.fc-ltr .fc-axis {
	text-align: right;
}

.fc-rtl .fc-axis {
	text-align: left;
}


/* TimeGrid Structure
--------------------------------------------------------------------------------------------------*/

.fc-time-grid-container, /* so scroll container's z-index is below all-day */
.fc-time-grid { /* so slats/bg/content/etc positions get scoped within here */
	position: relative;
	z-index: 1;
}

.fc-time-grid {
	min-height: 100%; /* so if height setting is 'auto', .fc-bg stretches to fill height */
}

.fc-time-grid table { /* don't put outer borders on slats/bg/content/etc */
	border: 0 hidden transparent;
}

.fc-time-grid > .fc-bg {
	z-index: 1;
}

.fc-time-grid .fc-slats,
.fc-time-grid > hr { /* the <hr> TimeGridView injects when grid is shorter than scroller */
	position: relative;
	z-index: 2;
}

.fc-time-grid .fc-content-col {
	position: relative; /* because now-indicator lives directly inside */
}

.fc-time-grid .fc-content-skeleton {
	position: absolute;
	z-index: 3;
	top: 0;
	left: 0;
	right: 0;
}

/* divs within a cell within the fc-content-skeleton */

.fc-time-grid .fc-business-container {
	position: relative;
	z-index: 1;
}

.fc-time-grid .fc-bgevent-container {
	position: relative;
	z-index: 2;
}

.fc-time-grid .fc-highlight-container {
	position: relative;
	z-index: 3;
}

.fc-time-grid .fc-event-container {
	position: relative;
	z-index: 4;
}

.fc-time-grid .fc-now-indicator-line {
	z-index: 5;
}

.fc-time-grid .fc-mirror-container { /* also is fc-event-container */
	position: relative;
	z-index: 6;
}


/* TimeGrid Slats (lines that run horizontally)
--------------------------------------------------------------------------------------------------*/

.fc-time-grid .fc-slats td {
	height: 1.5em;
	border-bottom: 0; /* each cell is responsible for its top border */
}

.fc-time-grid .fc-slats .fc-minor td {
	border-top-style: dotted;
}


/* TimeGrid Highlighting Slots
--------------------------------------------------------------------------------------------------*/

.fc-time-grid .fc-highlight-container { /* a div within a cell within the fc-highlight-skeleton */
	position: relative; /* scopes the left/right of the fc-highlight to be in the column */
}

.fc-time-grid .fc-highlight {
	position: absolute;
	left: 0;
	right: 0;
	/* top and bottom will be in by JS */
}


/* TimeGrid Event Containment
--------------------------------------------------------------------------------------------------*/

.fc-ltr .fc-time-grid .fc-event-container { /* space on the sides of events for LTR (default) */
	margin: 0 2.5% 0 2px;
}

.fc-rtl .fc-time-grid .fc-event-container { /* space on the sides of events for RTL */
	margin: 0 2px 0 2.5%;
}

.fc-time-grid .fc-event,
.fc-time-grid .fc-bgevent {
	position: absolute;
	z-index: 1; /* scope inner z-index's */
}

.fc-time-grid .fc-bgevent {
	/* background events always span full width */
	left: 0;
	right: 0;
}


/* TimeGrid Event Styling
----------------------------------------------------------------------------------------------------
  We use the full "fc-time-grid-event" class instead of using descendants because the event won't
  be a descendant of the grid when it is being dragged.
*/

.fc-time-grid-event {
	margin-bottom: 1px;
}

.fc-time-grid-event-inset {
	box-shadow: 0px 0px 0px 1px var(--event-inset-shadow);
}

.fc-time-grid-event.fc-not-start { /* events that are continuing from another day */
	/* replace space made by the top border with padding */
	border-top-width: 0;
	padding-top: 1px;

	/* remove top rounded corners */
	border-top-left-radius: 0;
	border-top-right-radius: 0;
}

.fc-time-grid-event.fc-not-end {
	/* replace space made by the top border with padding */
	border-bottom-width: 0;
	padding-bottom: 1px;

	/* remove bottom rounded corners */
	border-bottom-left-radius: 0;
	border-bottom-right-radius: 0;
}

.fc-time-grid-event .fc-content {
	overflow: hidden;
	max-height: 100%;
}

.fc-time-grid-event .fc-time,
.fc-time-grid-event .fc-title {
	padding: 0 1px;
}

.fc-time-grid-event .fc-time {
	font-size: .85em;
	white-space: nowrap;
}

/* short mode, where time and title are on the same line */

.fc-time-grid-event.fc-short .fc-content {
	/* don't wrap to second line (now that contents will be inline) */
	white-space: nowrap;
}

.fc-time-grid-event.fc-short .fc-time,
.fc-time-grid-event.fc-short .fc-title {
	/* put the time and title on the same line */
	display: inline-block;
	vertical-align: top;
}

.fc-time-grid-event.fc-short .fc-time span {
	display: none; /* don't display the full time text... */
}

.fc-time-grid-event.fc-short .fc-time:before {
	content: attr(data-start); /* ...instead, display only the start time */
}

.fc-time-grid-event.fc-short .fc-time:after {
	content: "\000A0-\000A0"; /* seperate with a dash, wrapped in nbsp's */
}

.fc-time-grid-event.fc-short .fc-title {
	font-size: .85em; /* make the title text the same size as the time */
	padding: 0; /* undo padding from above */
}

/* resizer (cursor device) */

.fc-time-grid-event.fc-allow-mouse-resize .fc-resizer {
	left: 0;
	right: 0;
	bottom: 0;
	height: 8px;
	overflow: hidden;
	line-height: 8px;
	font-size: 11px;
	font-family: monospace;
	text-align: center;
	cursor: s-resize;
}

.fc-time-grid-event.fc-allow-mouse-resize .fc-resizer:after {
	content: "=";
}

/* resizer (touch device) */

.fc-time-grid-event.fc-selected .fc-resizer {
	/* 10x10 dot */
	border-radius: 5px;
	border-width: 1px;
	width: 8px;
	height: 8px;
	border-style: solid;
	border-color: inherit;
	background: var(--resizer-bg);
	/* horizontally center */
	left: 50%;
	margin-left: -5px;
	/* center on the bottom edge */
	bottom: -5px;
}


/* Now Indicator
--------------------------------------------------------------------------------------------------*/

.fc-time-grid .fc-now-indicator-line {
	border-top-width: 1px;
	left: 0;
	right: 0;
}

/* arrow on axis */

.fc-time-grid .fc-now-indicator-arrow {
	margin-top: -5px; /* vertically center on top coordinate */
}

.fc-ltr .fc-time-grid .fc-now-indicator-arrow {
	left: 0;
	/* triangle pointing right... */
	border-width: 5px 0 5px 6px;
	border-top-color: transparent;
	border-bottom-color: transparent;
}

.fc-rtl .fc-time-grid .fc-now-indicator-arrow {
	right: 0;
	/* triangle pointing left... */
	border-width: 5px 6px 5px 0;
	border-top-color: transparent;
	border-bottom-color: transparent;
}
