.popin {
	position: fixed; top: 0; right: 0; bottom: 0; left: 0; z-index: $z-index-popin;
	display: flex; padding: 5%; overflow: hidden;
	flex-flow: column nowrap; justify-content: center; align-items: center;
	background-color: var(--default-backdrop-bg);
	transition: $popin-duration $popin-timing-function;

	&.fade-enter,
	&.fade-leave-to {
		opacity: 0;
	}

	&.fade-enter {
		.popin-frame {
			transform: translateY(-2em);
		}
	}

	&.fade-leave-to {
		.popin-frame {
			transform: translateY(2em);
		}
	}

	@include media('>medium') {
		@supports (filter: blur(5px)) {
			&.fade-leave-to ~ .l-app {
				filter: blur(0);
			}

			& ~ .l-app {
				filter: blur(3px);
			}
		}
	}

	&-frame {
		display: flex; width: 100%; max-width: 30em; overflow: hidden;
		flex: 0 1 auto; flex-flow: column nowrap; justify-content: flex-start; align-items: stretch;
		border-radius: var(--global-border-radius);
		background-color: var(--popin-bg);
		transition: inherit;

		&.is-wide {
			max-width: 60em;
		}

		&-title {
			padding: $global-gutters;
			flex: 0 0 auto;
			background-color: var(--popin-title-bg);
			color: var(--popin-title-color); font-size: 1.25em; font-weight: var(--font-weight-bold);
		}

		&-content {
			@extend .scrollbar;
			overflow: auto;
			flex: 1 1 auto;

			&-inner {
				margin-top: $global-gutters; margin-bottom: $global-gutters;
			}
		}

		&-footer {
			@extend .control-bar;
			padding: $global-gutters;
			border-radius: 0 0 var(--global-border-radius) var(--global-border-radius);
			background-color: var(--popin-footer-bg);
		}
	}

	&-person-search {
		display: flex; min-height: 50vh;
		flex-flow: column nowrap; justify-content: flex-start; align-items: stretch;

		&-no-result {
			display: flex;
			flex: 1 1 auto;

			& > * {
				margin: auto;
			}

			&.is-white-space {
				opacity: .25;
			}
		}

		&-item {
			display: flex;
			flex-flow: row nowrap; justify-content: flex-start; align-items: center;

			.radio {
				flex: 0 0 auto;
			}

			.misc-item {
				margin-left: $global-gutters;
				flex: 1 1 auto;
			}
		}
	}
}
