.input-phone {
	position: relative;

	&-flag {
		position: static;

		.dropdown-menu {
			@include media('>medium') {
				max-width: calc(100% - 5em); margin-left: 5em;
			}
		}
	}

	.dropdown {
		flex: 0 0 5em;
	}

	.last-preferred {
		border-bottom: 1px solid var(--hr-bg);
	}
}
