.event {
	&-header {
		position: sticky; top: 0; z-index: $z-index-sticky;

		&-wrapper {
			padding: $global-gutters 0;
			border-bottom: 1px solid var(--hr-bg);
			background-color: var(--event-header-bg);
		}

		&-flex {
			display: flex;
			flex-flow: row nowrap; align-items: center;

			& > * {
				flex: 1 0 auto;
			}
		}

		&-avatar {
			margin-right: .25em;
			flex: 0 0 auto;
			font-size: 3.25em;
		}

		&-title {
			margin: 0;
			font-size: 1.5em;
		}
	}
}

.recurring-weekdays {
	display: flex; margin: 0 -.5em;
	flex-flow: row wrap; justify-content: space-between; align-content: center;

	& > * {
		margin-left: .5em; margin-right: .5em;
	}
}

.l-context .fc {
	background-color: var(--default-bg);
}
