html,
body,
.l-page {
	height: 100%;
}

.l-page,
.l-app,
.l-sub-app {
	display: flex; overflow: hidden;
	flex-wrap: nowrap; justify-content: flex-start; align-items: stretch;
}

.l-page {
	flex-direction: column;
}

.l-app {
	flex: 1 1 auto;	flex-direction: column;
}

.l-sub-app {
	flex: 1 1 auto; flex-direction: row;
}

.l-header {
	@include media('<=small') {
		display: flex;
		flex: 0 0 auto;
	}

	@include media('>small') {
		display: none;
	}
}

.l-navigation {
	z-index: $z-index-navigation;
	transition: $panel-duration $default-timing-function;

	@include media('<=small') {
		position: fixed; top: 0; bottom: 0; left: 0;
		width: 12em;
		transform: translateX(-100%);

		&.is-open {
			transform: translateX(0);
		}
	}

	@include media('>small') {
		position: relative;
		min-width: 0;
		flex: 0 0 3.25em;

		&.is-expanded {
			--width: 10%;
			width: var(--width); min-width: 12em;
			flex: 0 0 var(--width);
		}
	}
}

.l-container {
	overflow: hidden;
	flex: 1 1 auto;

	@include media('<=medium') {
		position: relative;
		display: flex;
		flex-flow: column nowrap; justify-content: flex-start; align-items: stretch;
	}

	@include media('>medium') {
		display: grid; min-height: 0;
		grid-template-columns: minmax(15em, 2fr) 10fr;
		grid-template-rows: auto 1fr;
		grid-template-areas: "aside breadcrumb" "aside master";
		place-items: stretch;

		&.is-without-aside {
			grid-template-columns: 1fr;
			grid-template-areas: "breadcrumb" "master";
		}
	}
}

.l-loading {
	height: 100%;

	@include media('>medium') {
		grid-area: master;
	}
}

.l-aside {
	min-width: 15em;
	transition: $panel-duration $default-timing-function, color 0s; // For skin switching

	@include media('<=medium') {
		position: absolute; top: 0; bottom: 0; left: 0; z-index: $z-index-aside;
		width: 45%;
		transform: translateX(-100%);

		&.is-open {
			transform: translateX(0);
		}
	}

	@include media('>medium') {
		min-height: 0;
		grid-area: aside;
	}
}

.l-breadcrumb {
	overflow: hidden;

	@include media('<=medium') {
		flex: 0 0 auto;
	}

	@include media('>medium') {
		grid-area: breadcrumb;
	}
}

.l-master {
	position: relative;
	display: flex; overflow: hidden;
	flex-flow: row nowrap; justify-content: flex-start; align-items: stretch;

	@include media('<=medium') {
		flex: 1 1 auto;
	}

	@include media('>medium') {
		min-height: 0;
		grid-area: master;
	}
}

.l-central {
	flex: 1 1 auto;
}

.l-context {
	--min-width: 20em;

	transition: $panel-duration $default-timing-function, border-left-color 0s;

	&,
	&-inner,
	&-inner-scroll {
		display: flex;
		flex: 1 1 auto; flex-flow: column nowrap; justify-content: flex-start; align-items: stretch;
	}

	&,
	&-inner {
		overflow: hidden;
	}

	&-inner {
		min-width: var(--min-width);
	}

	&-inner-scroll {
		& > * {
			width: 100%; box-sizing: border-box;
		}
	}

	@include media('<=large') {
		position: absolute; top: 0; right: 0; bottom: 0; z-index: $z-index-context;
		width: 45%; min-width: var(--min-width);
	}

	@include media('<=small') {
		&.is-mobile-closed {
			transform: translateX(100%);
		}
	}

	@include media('>small', '<=large') {
		transform: translateX(100%);

		&.is-open {
			transform: translateX(0);
		}
	}

	@include media('>large') {
		width: 0; min-width: 0;
		flex: 0 0 0;

		&.is-open {
			--width: 25%;
			width: var(--width); min-width: var(--min-width);
			flex: 0 0 var(--width);

			.is-without-aside & {
				--width: 20.834%; // Magic number to keep same width than with aside bar
			}
		}
	}
}

.l-navigation,
.l-aside,
.l-central,
.l-context-inner-scroll {
	@extend .scrollbar;
	overflow: auto;
}
