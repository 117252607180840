.fc {
	.substitute {
		background: none;
		opacity: 1;

		&::before {
			content: '';
			position: absolute; top: 0; right: 0; bottom: 0; left: 0;
			background-color: var(--bgevent);
			opacity: .3;
		}

		&::after {
			content: var(--title);
			position: absolute; top: 0; left: 0; right: 0;
			overflow: hidden;
			padding: .25em .25em 0;
			font-size: .75em; white-space: nowrap; text-overflow: ellipsis; font-style: italic;
			opacity: .75;
		}
	}
}

.fc-scroller {
	@extend .scrollbar;
}
