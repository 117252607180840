@font-face {
	font-family: 'Open Sans';
	font-style: normal;
	font-weight: 600;
	font-display: swap;
	src: local('Open Sans SemiBold'),
	     local('OpenSans-SemiBold'),
	     url('../../../fonts/open-sans-semibold.woff2') format('woff2');
}

@font-face {
	font-family: 'Merriweather';
	font-style: normal;
	font-weight: 400;
	font-display: swap;
	src: local('Merriweather Regular'),
	     local('Merriweather-Regular'),
	     url('../../../fonts/merriweather-regular.woff2') format('woff2');
}

@font-face {
	font-family: 'Merriweather';
	font-style: normal;
	font-weight: 700;
	font-display: swap;
	src: local('Merriweather Bold'),
	     local('Merriweather-Bold'),
	     url('../../../fonts/merriweather-bold.woff2') format('woff2');
}
