.misc-trigger {
	&-arrow {
		display: inline-block;
		transition: transform $default-duration $default-timing-function;
	}

	&.is-active &-arrow {
		transform: rotate(180deg);
	}
}
