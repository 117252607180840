.patient-list-link {
	padding: 1em;
	border: 1px solid var(--input-border-color); border-radius: var(--global-border-radius);
	background-color: var(--input-bg);
	text-align: center;

	&[disabled] {
		opacity: .25;
	}
}

.patients-search {
	&-container {
		flex: 1 0 auto;
	}

	&-item {
		margin: 1em 0;
		border: 1px solid var(--search-filter-border-color); border-radius: var(--global-border-radius);
		background: var(--search-filter-bg);

		& > * {
			padding: .5em;
		}

		&-header {
			display: flex;
			flex-flow: row nowrap; justify-content: flex-start; align-items: center;

			a {
				display: block;
			}
		}

		&-avatar {
			width: 1em; height: 1em; overflow: hidden;
			flex: 0 0 1em;
			border-radius: var(--global-border-radius);
			background-color: var(--aside-bg);
			font-size: #{$default-line-height * 2em};
		}

		&-name {
			margin-left: .5em;
			flex: 1 1 auto;
		}

		&-border {
			border-top: 1px solid var(--aside-hr-alt-bg);
		}

		i {
			opacity: .5;
		}
	}

	&-no-result {
		margin: 50% 0 0;

		&.is-white-space {
			opacity: .25;
		}
	}
}
