.input-address {

	&-field {
		position: relative;
	}

	&-list {
		--max-height: 16em;
		@extend %ul-reset, .scrollbar;
		position: absolute; top: 100%; right: 0; left: 0; z-index: $z-index-dropdown;
		width: 100%; max-height: var(--max-height); box-sizing: border-box; overflow: hidden; overflow-y: auto;
		transform: translateY(-1em);
		border: 1px solid var(--dropdown-border-color); border-radius: var(--global-border-radius); box-shadow: 0 .125em .25em var(--default-box-shadow);
		background-color: var(--dropdown-bg);
		text-align: left;
		opacity: 0; visibility: hidden; pointer-events: none;
		transition: $default-duration $default-timing-function;

		&.is-active {
			transform: translateY(0);
			opacity: 1; visibility: visible; pointer-events: auto;
		}

		&-item {
			@extend .btn--reset;
			display: block; width: 100%; box-sizing: 100%;
			background-color: var(--dropdown-item-bg);
			color: var(--dropdown-item-color);

			@include media('<=medium') {
				padding: 1em;
			}

			@include media('>medium') {
				padding: .5em 1em;
			}
		}
	}

}
