.skin-#{$skin-name} {

	.v-select {

		// Same as dropdown menu styles
		--vs-dropdown-bg          : #{$white};

		// Same as dropdown items default styles
		--vs-dropdown-option-color: inherit;

		// Same as dropdown items hover styles
		--vs-state-active-bg      : #{rgba($primary-color, .125)};
		--vs-state-active-color   : var(--primary-color);

		// Same as dropdown selected items styles
		--vs-selected-bg          : var(--primary-color);
		--vs-selected-color       : #{$white};
	}

	.vue-slider {
		--vsl-theme               : var(--primary-color);
		--vsl-bg                  : #{darken($theme-5, 10%)}; // Same  as --default-bg
		--vsl-dot-shadow-focus    : 0 0 1px 2px #{rgba($primary-color, 0.36)};
		--vsl-dot-bg              : var(--input-bg);
	}

	// Full Calendar component
	.fc {
		--border-color           : var(--default-border-color);
		--popover-bg             : #{$white};
		--popover-shadow         : var(--default-box-shadow);
		--divider-bg             : #{$theme-4};
		--today                  : #fcf8e3;
		--disabled-day-bg        : #{$lightgrey};
		--highlight              : #{lighten($theme-primary, 20%)};
		--bgevent                : #{darken($theme-5, 20%)};
		--nonbusiness            : #{$lightgrey};

		--event-bg               : var(--primary-color);
		--event-color            : #{$white};
		--event-shadow           : var(--default-box-shadow);
		--event-inset-shadow     : var(--default-bg);
		--event-selected-overlay : black;

		--resizer-bg             : #{$white};
		--now-indicator          : var(--danger-color);

		--list-empty-bg          : #{$lightgrey};

		--list-item-hover-bg     : #{$white};
	}
	.calendar {
		.is-unavailable {
			--bg-color           : #{$lightgrey}; // Same as --nonbusiness
		}

		.is-substitute {
			--bg-color           : #{darken($theme-5, 20%)}; // Same as --bgevent
		}
	}

	.apexcharts {
		&-canvas {
			--track-color        : var(--default-bg);
		}
	}

}
