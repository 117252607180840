@keyframes slide-in {
	from { transform: translateY(-100%); }
	to   { transform: translateY(0); }
}

@keyframes spinner-loader {
	from { transform: rotate(0); }
	to   { transform: rotate(360deg); }
}

@keyframes spinner-circle {
	from { transform: rotate(0); }
	90%  { transform: rotate(1080deg); }
	to   { transform: rotate(1080deg); }
}
