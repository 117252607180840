.sticky-note {
	$dark-color : #444;
	$light-color: #fff;

	--bg: var(--body-bg);
	--size: 15em;

	position: relative;
	display: flex; width: var(--size); height: var(--size); margin: $global-gutters / 2;
	transform: rotate(-2deg);
	flex: 0 0 var(--size);
	justify-content: center; align-items: stretch;
	color: var(--default-color);
	transition: $popin-duration $popin-timing-function;

	&::before,
	&::after {
		content: '';
		position: absolute; top: 0; right: 0; bottom: 0; left: 0;
		pointer-events: none;
		transition: inherit;
	}

	&::before {
		z-index: 2;
		background: linear-gradient(20deg, var(--bg) 0%, transparent 30%);
		filter: brightness(120%);
	}

	&::after {
		z-index: 1;
		background-color: var(--bg);
	}

	&-shadow {
		content: '';
		position: absolute; top: 80%; right: .5em; bottom: 1em; left: .5em; z-index: 0;
		transform: rotate(-2.5deg); transform-origin: bottom right;
		box-shadow: 0 1em .5em var(--default-box-shadow);
	}

	&.is-dark {
		color: $dark-color;
	}

	&.is-light {
		color: $light-color;
	}

	&.appear-enter,
	&.appear-leave-to {
		opacity: 0;
	}

	&.appear-enter {
		transform: scale(1.1) translateY(-1em);
	}

	&.appear-leave {

	}

	&.appear-leave-to {
		transform: scale(1.1) translateY(-1em);
	}

	&-loading {
		display: flex; width: 100%;
		justify-content: center; align-items: center;
		background-color: var(--bg);
	}

	&-container {
		display: flex; margin: $global-gutters / -2;
		flex-flow: row wrap; justify-content: center; align-items: flex-start;

		@include media('>small') {
			justify-content: flex-start;
		}
	}

	@include media('>large') {
		&:hover &-control,
		&:focus-within &-control {
			opacity: .5;
		}
	}

	& &-control { // Specificity -_-v
		position: absolute; z-index: 4;
		padding: .25em;
		border: 1px solid transparent;
		background-color: var(--bg);
		color: inherit;
		opacity: .5;

		@include media('>large') {
			opacity: 0;
		}

		&:hover,
		&:focus {
			border-color: var(--primary-color);
			background-color: var(--default-bg);
			color: var(--primary-color);
			opacity: 1;
		}
	}

	&-color {
		top: 0; left: 0;
		display: block;
	}

	&-delete  {
		top: 0; right: 0;
	}

	&-share {
		bottom: 0; left: 0;
	}

	&-spinner {
		position: absolute; bottom: .25em; right: .25em; z-index: 2;
		color: inherit;
	}

	&-input {
		position: relative; z-index: 3;
		display: flex; padding: 1rem;
		justify-content: center; align-items: center;
		font-size: var(--font-size); line-height: 1.25; text-align: center;
		resize: none;

		&,
		&:focus {
			border: none; box-shadow: none; border-radius: 0;
			background-color: transparent;
			color: inherit;
		}
	}

	&.is-dark &-input::placeholder {
		color: #{rgba($dark-color, .4)};
	}

	&.is-light &-input::placeholder {
		color: #{rgba($light-color, .4)};
	}

	&.is-dark &-input:focus::placeholder {
		color: #{rgba($dark-color, .2)};
	}

	&.is-light &-input:focus::placeholder {
		color: #{rgba($light-color, .2)};
	}
}
