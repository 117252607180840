.twin-textarea {

	.input-frame,
	.input-frame-like,
	.textarea-container {
		display: flex;
		flex-flow: column nowrap; justify-content: flex-start; align-items: stretch;

	}

	.textarea-container,
	textarea {
		flex: 1 0 auto;
	}
}
