.aside-search,
.context-search {
	position: relative;

	&-icon {
		position: absolute; top: 50%; left: .5em; z-index: 1;
		width: 1em; height: 1em;
		transform: translateY(-50%);
		opacity: 0; pointer-events: none;
		transition: opacity .25s $default-timing-function;

		&.is-shown {
			opacity: 1;
		}
	}

	&-input {
		padding: .5em .5em .5em 2em;

		&::-webkit-search-cancel-button {
			width: 1em; height: 1em;
			background-position: center; background-repeat: no-repeat;
			appearance: none;
		}
	}

	&-sticky-container {
		position: sticky; top: 0; z-index: 5;
		margin: 0 #{$global-gutters * -1}; padding: $global-gutters;

		&.is-bottom {
			top: initial; bottom: 0;
		}
	}
}

.aside-search {
	&-icon {
		color: var(--aside-search-color);
	}

	&-input {
		border: 1px solid var(--aside-search-border-color); box-shadow: 0 0 0 .125em var(--aside-search-shadow-color);
		background: var(--aside-search-bg);
		color: var(--aside-search-color);

		&::placeholder {
			color: var(--aside-search-placeholder-color);
		}

		&:focus {
			border-color: var(--aside-search-focus-border-color);  box-shadow: 0 0 0 .125em var(--aside-search-focus-shadow-color);
			background: var(--aside-search-focus-bg);
			color: var(--aside-search-focus-color);

			&::placeholder {
				color: var(--aside-search-focus-placeholder-color);
			}
		}
	}

	&-container {
		display: flex; height: 100%;
		flex-flow: column nowrap;
	}

	&-sticky-container {
		flex: 0 0 auto;
		background: linear-gradient(to bottom, var(--aside-sticky-bg) 0%, var(--aside-sticky-bg) 90%, transparent 100%);

		&.is-bottom {
			background: linear-gradient(to top, var(--aside-sticky-bg) 0%, var(--aside-sticky-bg) 90%, transparent 100%);
		}
	}
}

.context-search {
	&-sticky-container {
		background: linear-gradient(to bottom, var(--context-sticky-bg) 0%, var(--context-sticky-bg) 90%, transparent 100%);
	}
}
