[class*=' notice-'],
[class^='notice-'] {
	display: block; padding: .5em;
	border: 1px solid var(--notice-border-color); border-radius: var(--global-border-radius);
	background-color: var(--notice-bg);
	color: var(--notice-color); text-align: center;

	a:not(.btn),
	.btn--link-like {
		color: inherit;
	}

	& > *:first-child {
		margin-top: 0;
	}

	& > *:last-child {
		margin-bottom: 0;
	}
}
