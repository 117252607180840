.attachment {
	padding: .5em;
	border: 1px solid var(--input-border-color); border-radius: var(--global-border-radius);
	background-color: var(--input-bg);

	&-pic {
		margin: .5em 0;
		border: 1px solid var(--default-border-color);
	}
}
