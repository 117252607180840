.l-navigation {
	display: flex;
	flex-flow: column nowrap; justify-content: flex-start; align-items: stretch;
	border-right: var(--navigation-border);
	background-color: var(--navigation-bg);
	color: var(--navigation-item-color);

	& > * {
		flex: 0 0 auto;
	}

	hr {
		margin: .5em 0;
		background-color: var(--navigation-hr-bg);
	}

	@include media('>small') {
		&.is-expanded {
			.nav-item {
				&-switch {
					&,
					&:hover,
					&:focus {
						svg {
							transform: rotate(180deg);
						}
					}
				}

				&:not(.nav-item-account).router-link-active {
					border-right-width: .5em;
				}
			}
		}
	}
}

.nav-item {
	display: flex; overflow: hidden;
	flex-flow: row nowrap; justify-content: flex-start; align-items: center;
	border-right: 0 solid var(--navigation-item-active-border);
	font-weight: var(--font-weight-bold); color: var(--navigation-item-color); text-decoration: none;

	&:hover,
	&:focus {
		background-color: var(--navigation-item-hover-bg);
		color: var(--navigation-item-hover-color);
	}

	&:hover &-icon,
	&:focus &-icon {
		svg {
			transform: translateX(.125em);
		}
	}

	&:hover &-avatar,
	&:focus &-avatar {
		&::after {
			@supports not (mix-blend-mode: overlay) {
				opacity: .25;
			}

			@supports (mix-blend-mode: overlay) {
				opacity: 1;
			}
		}
	}

	&-switch,
	&-account,
	&-logout {
		&:hover,
		&:focus {
			.nav-item-icon svg {
				transform: none;
			}
		}
	}

	&-icon {
		position: relative;
		padding: .75em 1em;

		svg {
			font-size: 1.25em;
			transition: transform $default-duration $default-timing-function;
		}
	}

	&-avatar {
		position: relative;
		height: 1em;
		flex: 0 0 1em;
		font-size: 3.25em;

		&::after {
			position: absolute; top: 0; right: 0; bottom: 0; left: 0;
			background-color: var(--navigation-bg);
			content: ''; opacity: 0;
			transition: opacity $default-duration $default-timing-function;

			@supports (mix-blend-mode: overlay) {
				mix-blend-mode: overlay;
			}
		}

		&.is-anonymous {
			display: flex;

			svg {
				margin: auto;
				font-size: .55em;
			}
		}
	}

	&-avatar + &-label {
		padding-left: .5em;
	}

	&-badge {
		position: absolute; top: 0; right: .5em; z-index: 1;
		display: flex; min-width: 1.5em; height: 1.5em; padding: 0 .25em; box-sizing: border-box;
		justify-content: center; align-items: center;
		border-radius: .75em;
		background-color: red;
		font-family: monospace; font-size: .75em; line-height: 1; color: white; text-align: center;
	}

	&-label {
		padding-right: .5em; overflow: hidden;
		white-space: nowrap;
		transition: opacity $default-duration $default-timing-function;
	}

	&.router-link-active {
		z-index: 1;
		box-shadow: 0 0 1em var(--navigation-item-active-bg);
		background-color: var(--navigation-item-active-bg);
		color: var(--navigation-item-active-color);

		@include media('>small') {
			border-right-width: .25em;

			.is-without-aside & {
				border-right-width: 0;
			}
		}
	}

	&-switch {
		margin-bottom: 1em;
		background-color: var(--navigation-switch-bg);
		color: var(--navigation-switch-color);

		&:hover,
		&:focus {
			background-color: var(--navigation-switch-hover-bg);
			color: var(--navigation-switch-hover-color);
		}

		.nav-item-icon {
			padding: .5em 1em;
			opacity: 1;
		}

		svg {
			transition: transform $default-duration $default-timing-function;
		}
	}

	&-rdv {
		display: flex; margin-top: 1em; padding: .5em 1em;
		flex-flow: row nowrap; justify-content: center; align-items: center; align-self: center;
		border: none; border-radius: 1.25em;
		background-color: var(--navigation-account-bg);
		color: var(--navigation-account-color); text-align: center;

		&:hover,
		&:focus {
			outline: none;
			background-color: var(--navigation-account-hover-bg);
			color: var(--navigation-account-hover-color);
		}

		&-icon {
			margin-bottom: .25em;
			transition: $panel-duration $default-timing-function, color 0s;
		}

		&-text {
			max-width: 7em; margin-left: .25em; overflow: hidden;
			white-space: nowrap;
			transition: $panel-duration $default-timing-function, color 0s;
		}

		.l-navigation:not(.is-expanded) & {
			padding: .75em;

			&-icon {
				margin-bottom: 0;
			}

			&-text {
				max-width: 0; margin-left: 0;
			}
		}
	}

	&-account {
		background-color: var(--navigation-account-bg);
		color: var(--navigation-account-color);

		.nav-item-label {
			width: 100%;
			line-height: 1.3;
		}

		&-profile {
			opacity: .5;
			transition: opacity $default-duration $default-timing-function;
		}

		&.router-link-active {
			border-right-width: 0;
			background-color: var(--navigation-account-active-bg);
			color: var(--navigation-account-active-color);
		}

		&:hover,
		&:focus {
			background-color: var(--navigation-account-hover-bg);
			color: var(--navigation-account-hover-color);

			.nav-item-account-profile {
				opacity: 1;
			}
		}
	}

	&-logout {
		background-color: var(--navigation-logout-bg);
		color: var(--navigation-logout-color);

		&:hover,
		&:focus {
			background-color: var(--navigation-logout-hover-bg);
			color: var(--navigation-logout-hover-color);
		}
	}
}

.app-version {
	margin-top: auto; padding: 1em 0;

	&-btn {
		opacity: .25;

		&:hover,
		&:focus {
			opacity: 1;
		}
	}

	&-text {
		.l-navigation:not(.is-expanded) & {
			display: none;
		}
	}
}

.is-with-sub-nav {
	.nav-item {
		border-right-color: var(--navigation-item-active-border-alt);
	}
}
