.skin-item {
	&-img {
		width: auto; height: auto;
		border: 1px solid var(--default-border-color);

		&-container {
			position: relative;
		}
	}

	&-check {
		position: absolute;  bottom: .25em; right: .25em;
		font-size: 2em;
		opacity: 0;
		transition: inherit;
	}

	&.is-selected &-check {
		opacity: 1;
	}

	&-label {
		margin-top: 1em;
	}

	@for $i from 1 through 3 {
		&-color-#{$i} {
			fill: var(--color-#{$i});
		}
	}

	&[id='skin-default'] {
		--color-1: #085b6b;
		--color-2: #e7f0f1;
		--color-3: #f6fafb;
	}

	&[id='skin-dark'] {
		--color-1: #212535;
		--color-2: #414555;
		--color-3: #515565;
	}

	&[id='skin-light'] {
		--color-1: #f2f4fa;
		--color-2: #ffffff;
		--color-3: #ffffff;
	}
}
