.timetable {
	position: relative;
	display: grid;
	grid-template-columns: repeat(var(--col-count, 7), 1fr);
	grid-gap: $global-gutters; grid-auto-flow: row; justify-items: stretch; align-items: stretch;

	&-column {
		&-title {
			@extend .is-sticky-top;
			border-bottom: 1px solid var(--default-border-color);
			background-color: var(--timetable-alpha-bg);
			text-align: center;
			transition: $default-duration $default-timing-function;

			&-inner {
				padding: 1em 0;
				transition: background-color $default-duration $default-timing-function;
			}

			&.is-today {
				color: var(--primary-color); font-weight: var(--font-weight-bold);
			}

			&.is-selected {
				border-bottom-color: var(--primary-color);
				color: var(--togglebox-hover-color); font-weight: var(--font-weight-bold);
			}

			&.is-selected &-inner {
				background-color: var(--togglebox-hover-bg);
			}
		}
	}

	&-slot {
		display: block; margin: $global-gutters 0;
		text-align: center;

		&.is-hidden {
			display: none;
		}
	}

	&.is-loading {
		pointer-events: none;
	}

	&.is-loading &-slot {
		border-color: currentColor;
		background-color: currentColor;
		opacity: .05;
		animation: loading-slot .5s calc(var(--factor, 0) * 50ms) easing(easeOutSine) infinite alternate;
	}

	@keyframes loading-slot {
		from { opacity: .05; }
		to   { opacity: .2; }
	}

	&-is-empty {
		position: absolute; top: 0; right: 0; bottom: 0; left: 0; z-index: 1;
		display: flex; padding-top: 7.125em;
		justify-content: center; align-items: center;
		background-color: var(--timetable-alpha-bg);
	}
}
