.calendar {
	&-frame {
		display: flex; min-height: 100%; box-sizing: border-box;
		flex: 1 1 auto; flex-flow: column nowrap; justify-content: flex-start; align-items: stretch;

		.fc {
			margin-bottom: -1px;
		}
	}

	&-header {
		padding: $global-gutters 0 0;

		&-title {
			margin: 0 .5em $global-gutters;
			flex: 1 1 100%;
			text-align: center;

			@include media('>small') {
				flex-basis: auto;
				text-align: left;
			}
		}

		.toolbar-control {
			margin-bottom: $global-gutters;
		}
	}

	&-container {
		flex: 1 0 auto;
	}

	&-legend {
		&-item {
			display: inline-block; margin: .25em .5em .25em 0;
			white-space: nowrap;

			&-color {
				position: relative;
				display: inline-flex; width: 2.5em; height: 1.5em;
				border: 1px solid currentColor;
				background-color: var(--default-bg);
				vertical-align: middle;

				&.is-unvalid {
					border-width: 3px;
					font-weight: bold;
				}

				&.is-missed {
					text-decoration: line-through;
				}

				&.is-unvalid,
				&.is-missed,
				&.is-substitute {
					&::after {
						margin: auto;
						font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
						content: 'abc';
					}
				}

				&.is-unavailable {
					&::before {
						position: absolute; top: 0; right: 0; bottom: 0; left: 0;
						background-color: var(--bg-color);
						content: ''; opacity: .3; // Same as .fc-bgevent
					}
				}

				&.is-substitute {
					&::before {
						position: absolute; top: 0; right: 0; bottom: 0; left: 0;
						background-color: var(--bg-color);
						content: ''; opacity: .3; // Same as .fc-bgevent
					}

					&::after {
						font-size: .75em; font-style: italic;
						opacity: .75;
					}
				}
			}
		}
	}

}

.l-context {
	.osteo-list {
		.avatar {
			font-size: 1.5em;
		}
	}
}

.planning-list-item {

	&-header {
		border: 1px solid var(--hr-bg); border-radius: var(--global-border-radius);
	}

	&:not(.is-readonly) &-header {
		border-bottom-left-radius: 0; border-bottom-right-radius: 0; border-bottom: 0;
	}

	&-legend {
		border-top-left-radius: var(--global-border-radius); border-top-right-radius: var(--global-border-radius);
	}

	&-legend {
		height: 1em;
		border-bottom: 1px solid var(--hr-bg);
		background-color: var(--color);
	}

	&-title {
		padding: .5em;
	}

	.form-group-input {
		&:not(:first-child) {
			border-top-right-radius: 0;
		}
		&:not(:last-child) {
			border-top-left-radius: 0;
		}
	}

}
