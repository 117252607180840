.l-context {
	border-left: var(--context-border);
	background-color: var(--context-bg-rwd);

	@include media('>large') {
		background-color: var(--context-bg);

		&:not(.is-open) {
			border-left-color: var(--default-bg);
			transition-delay: 0s, $panel-duration;
		}
	}

	&-inner {
		position: relative;
		padding: .01em 0; // for margins
	}

	&-btn-close {
		@extend .btn--reset;
		position: absolute; top: 0; right: .5rem; z-index: 6;
		font-size: 2em;
		opacity: .5;

		&:hover,
		&:focus {
			color: var(--primary-color);
			opacity: 1;
		}
	}
}
