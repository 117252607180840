iframe {
	width: 100%;
	height: 100%;
	border: none;
}

.iframe-container {
	max-height: 700px;
	height: calc(85vh - 3.25em - 3.675em - 4rem - 3em); // -header -footer -margin
}
