.login-frame {
	position: relative; z-index: 1;
	width: 100%; box-sizing: border-box; overflow: hidden;
	border-radius: var(--global-border-radius); box-shadow: 0 0 3em rgba(black, .25);
	transition: .5s easing(easeOutQuart);

	&.fade-enter,
	&.fade-leave-to {
		opacity: 0;
	}

	&.fade-enter {
		transform: translateY(-.5em);
	}

	&.fade-leave {
		position: absolute; z-index: 0;
	}

	&.fade-leave-to {
		position: absolute; z-index: 0;
		transform: translateY(.5em);
	}

	&-title {
		margin: 0; padding: $global-gutters;
		background-color: var(--login-frame-title-bg);
		font-weight: var(--font-weight-bold); font-size: 1.5em;
	}

	&-content {
		padding: $global-gutters;
		background-color: var(--login-frame-bg);

		& > :first-child {
			margin-top: 0;
		}

		& > :last-child {
			margin-bottom: 0;
		}
	}

	hr {
		margin-left: -$global-gutters; margin-right: -$global-gutters;
	}
}
