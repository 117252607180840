//  Global Component Variables
$vs-component-line-height         : $default-line-height;
$vs-component-placeholder-color   : var(--input-placeholder-color);

//  Active State
$vs-state-active-bg               : var(--vs-state-active-bg);
$vs-state-active-color            : var(--vs-state-active-color);

//  Disabled State
//$vs-state-disabled-bg             : rgb(248, 248, 248) !default;
//$vs-state-disabled-color          : map_get($vs-colors, 'light') !default;
//$vs-state-disabled-controls-color : map_get($vs-colors, 'light') !default;
//$vs-state-disabled-cursor         : not-allowed !default;

//  Borders
$vs-border-width                  : 1px;
$vs-border-style                  : solid;
$vs-border-radius                 : var(--global-border-radius);
$vs-border-color                  : var(--input-border-color);

//  Component Controls: Clear, Open Indicator
$vs-controls-color                : var(--select-caret-color);
$vs-controls-size                 : 1;
$vs-controls-deselect-text-shadow : none;

//  Selected
$vs-selected-bg                   : var(--vs-selected-bg);
$vs-selected-border-color         : transparent;
$vs-selected-border-style         : none;
$vs-selected-border-width         : 0;

//  Dropdown
$vs-dropdown-z-index              : $z-index-dropdown;
$vs-dropdown-min-width            : 10em;
$vs-dropdown-max-height           : 16em;
$vs-dropdown-box-shadow           : 0 .125em .25em var(--default-box-shadow);;
$vs-dropdown-bg                   : var(--vs-dropdown-bg);
