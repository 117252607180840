
.fc {
	direction: ltr;
	text-align: left;
}

.fc-rtl {
	text-align: right;
}

body .fc { /* extra precedence to overcome jqui */
	font-size: 1em;
}


/* Colors
--------------------------------------------------------------------------------------------------*/


.fc-highlight { /* when user is selecting cells */
	background: var(--highlight);
	opacity: .3;
}

.fc-bgevent { /* default look for background events */
	background: var(--bgevent);
	opacity: .3;
}

.fc-nonbusiness { /* default look for non-business-hours areas */
	/* will inherit .fc-bgevent's styles */
	background: var(--nonbusiness);
}


/* Popover
--------------------------------------------------------------------------------------------------*/

.fc-popover {
	position: absolute;
	box-shadow: 0 2px 6px var(--popover-shadow);
}

.fc-popover .fc-header { /* TODO: be more consistent with fc-head/fc-body */
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	padding: 2px 4px;
}

.fc-rtl .fc-popover .fc-header {
	flex-direction: row-reverse;
}

.fc-popover .fc-header .fc-title {
	margin: 0 2px;
}

.fc-popover .fc-header .fc-close {
	cursor: pointer;
	opacity: 0.65;
	font-size: 1.1em;
}


/* Misc Reusable Components
--------------------------------------------------------------------------------------------------*/

.fc-divider {
	border-style: solid;
	border-width: 1px;
}

hr.fc-divider {
	height: 0;
	margin: 0;
	padding: 0 0 2px; /* height is unreliable across browsers, so use padding */
	border-width: 1px 0;
}

.fc-bg,
.fc-bgevent-skeleton,
.fc-highlight-skeleton,
.fc-mirror-skeleton {
	/* these element should always cling to top-left/right corners */
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
}

.fc-bg {
	bottom: 0; /* strech bg to bottom edge */
}

.fc-bg table {
	height: 100%; /* strech bg to bottom edge */
}


/* Tables
--------------------------------------------------------------------------------------------------*/

.fc table {
	width: 100%;
	box-sizing: border-box; /* fix scrollbar issue in firefox */
	table-layout: fixed;
	border-collapse: collapse;
	border-spacing: 0;
	font-size: 1em; /* normalize cross-browser */
}

.fc th {
	text-align: center;
}

.fc th,
.fc td {
	border-style: solid;
	border-width: 1px;
	padding: 0;
	vertical-align: top;
}

.fc td.fc-today {
	border-style: double; /* overcome neighboring borders */
}


/* Internal Nav Links
--------------------------------------------------------------------------------------------------*/

a[data-goto] {
	cursor: pointer;
}

a[data-goto]:hover {
	text-decoration: underline;
}


/* Fake Table Rows
--------------------------------------------------------------------------------------------------*/

.fc .fc-row { /* extra precedence to overcome themes forcing a 1px border */
	/* no visible border by default. but make available if need be (scrollbar width compensation) */
	border-style: solid;
	border-width: 0;
}

.fc-row table {
	/* don't put left/right border on anything within a fake row.
		 the outer tbody will worry about this */
	border-left: 0 hidden transparent;
	border-right: 0 hidden transparent;

	/* no bottom borders on rows */
	border-bottom: 0 hidden transparent;
}

.fc-row:first-child table {
	border-top: 0 hidden transparent; /* no top border on first row */
}


/* Day Row (used within the header and the DayGrid)
--------------------------------------------------------------------------------------------------*/

.fc-row {
	position: relative;
}

.fc-row .fc-bg {
	z-index: 1;
}

/* highlighting cells & background event skeleton */

.fc-row .fc-bgevent-skeleton,
.fc-row .fc-highlight-skeleton {
	bottom: 0; /* stretch skeleton to bottom of row */
}

.fc-row .fc-bgevent-skeleton table,
.fc-row .fc-highlight-skeleton table {
	height: 100%; /* stretch skeleton to bottom of row */
}

.fc-row .fc-highlight-skeleton td,
.fc-row .fc-bgevent-skeleton td {
	border-color: transparent;
}

.fc-row .fc-bgevent-skeleton {
	z-index: 2;

}

.fc-row .fc-highlight-skeleton {
	z-index: 3;
}

/*
row content (which contains day/week numbers and events) as well as "mirror" (which contains
temporary rendered events).
*/

.fc-row .fc-content-skeleton {
	position: relative;
	z-index: 4;
	padding-bottom: 2px; /* matches the space above the events */
}

.fc-row .fc-mirror-skeleton {
	z-index: 5;
}

.fc .fc-row .fc-content-skeleton table,
.fc .fc-row .fc-content-skeleton td,
.fc .fc-row .fc-mirror-skeleton td {
	/* see-through to the background below */
	/* extra precedence to prevent theme-provided backgrounds */
	background: none; /* in case <td>s are globally styled */
	border-color: transparent;
}

.fc-row .fc-content-skeleton td,
.fc-row .fc-mirror-skeleton td {
	/* don't put a border between events and/or the day number */
	border-bottom: 0;
}

.fc-row .fc-content-skeleton tbody td, /* cells with events inside (so NOT the day number cell) */
.fc-row .fc-mirror-skeleton tbody td {
	/* don't put a border between event cells */
	border-top: 0;
}


/* Scrolling Container
--------------------------------------------------------------------------------------------------*/

.fc-scroller {
	-webkit-overflow-scrolling: touch;
}

/* TODO: move to timegrid/daygrid */
.fc-scroller > .fc-day-grid,
.fc-scroller > .fc-time-grid {
	position: relative; /* re-scope all positions */
	width: 100%; /* hack to force re-sizing this inner element when scrollbars appear/disappear */
}


/* Global Event Styles
--------------------------------------------------------------------------------------------------*/

.fc-event {
	position: relative; /* for resize handle and other inner positioning */
	display: block; /* make the <a> tag block */
	font-size: .85em;
	line-height: 1.4;
	border-radius: 3px;
	border: 1px solid var(--event-bg);
}

.fc-event,
.fc-event-dot {
	background-color: var(--event-bg); /* default BACKGROUND color */
}

.fc-event,
.fc-event:hover {
	color: var(--event-color); /* default TEXT color */
	text-decoration: none; /* if <a> has an href */
}

.fc-event[href],
.fc-event.fc-draggable {
	cursor: pointer; /* give events with links and draggable events a hand mouse pointer */
}

.fc-not-allowed, /* causes a "warning" cursor. applied on body */
.fc-not-allowed .fc-event { /* to override an event's custom cursor */
	cursor: not-allowed;
}

.fc-event .fc-content {
	position: relative;
	z-index: 2;
}

/* resizer (cursor AND touch devices) */

.fc-event .fc-resizer {
	position: absolute;
	z-index: 4;
}

/* resizer (touch devices) */

.fc-event .fc-resizer {
	display: none;
}

.fc-event.fc-allow-mouse-resize .fc-resizer,
.fc-event.fc-selected .fc-resizer {
	/* only show when hovering or selected (with touch) */
	display: block;
}

/* hit area */

.fc-event.fc-selected .fc-resizer:before {
	/* 40x40 touch area */
	content: "";
	position: absolute;
	z-index: 9999; /* user of this util can scope within a lower z-index */
	top: 50%;
	left: 50%;
	width: 40px;
	height: 40px;
	margin-left: -20px;
	margin-top: -20px;
}


/* Event Selection (only for touch devices)
--------------------------------------------------------------------------------------------------*/

.fc-event.fc-selected {
	z-index: 9999 !important; /* overcomes inline z-index */
	box-shadow: 0 2px 5px var(--event-shadow);
}

.fc-event.fc-selected:after {
	content: "";
	position: absolute;
	z-index: 1; /* same z-index as fc-bg, behind text */
	/* overcome the borders */
	top: -1px;
	right: -1px;
	bottom: -1px;
	left: -1px;
	/* darkening effect */
	background: var(--event-selected-overlay);
	opacity: .25;
}


/* Event Dragging
--------------------------------------------------------------------------------------------------*/

.fc-event.fc-dragging.fc-selected {
	box-shadow: 0 2px 7px var(--event-shadow);
}

.fc-event.fc-dragging:not(.fc-selected) {
	opacity: .75;
}


/* Horizontal Events
--------------------------------------------------------------------------------------------------*/

/* bigger touch area when selected */
.fc-h-event.fc-selected:before {
	content: "";
	position: absolute;
	z-index: 3; /* below resizers */
	top: -10px;
	bottom: -10px;
	left: 0;
	right: 0;
}

/* events that are continuing to/from another week. kill rounded corners and butt up against edge */

.fc-ltr .fc-h-event.fc-not-start,
.fc-rtl .fc-h-event.fc-not-end {
	margin-left: 0;
	border-left-width: 0;
	padding-left: 1px; /* replace the border with padding */
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
}

.fc-ltr .fc-h-event.fc-not-end,
.fc-rtl .fc-h-event.fc-not-start {
	margin-right: 0;
	border-right-width: 0;
	padding-right: 1px; /* replace the border with padding */
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
}

/* resizer (cursor AND touch devices) */

/* left resizer  */
.fc-ltr .fc-h-event .fc-start-resizer,
.fc-rtl .fc-h-event .fc-end-resizer {
	cursor: w-resize;
	left: -1px; /* overcome border */
}

/* right resizer */
.fc-ltr .fc-h-event .fc-end-resizer,
.fc-rtl .fc-h-event .fc-start-resizer {
	cursor: e-resize;
	right: -1px; /* overcome border */
}

/* resizer (mouse devices) */

.fc-h-event.fc-allow-mouse-resize .fc-resizer {
	width: 7px;
	top: -1px; /* overcome top border */
	bottom: -1px; /* overcome bottom border */
}

/* resizer (touch devices) */

.fc-h-event.fc-selected .fc-resizer {
	/* 8x8 little dot */
	border-radius: 4px;
	border-width: 1px;
	width: 6px;
	height: 6px;
	border-style: solid;
	border-color: inherit;
	background: var(--resizer-bg);
	/* vertically center */
	top: 50%;
	margin-top: -4px;
}

/* left resizer  */
.fc-ltr .fc-h-event.fc-selected .fc-start-resizer,
.fc-rtl .fc-h-event.fc-selected .fc-end-resizer {
	margin-left: -4px; /* centers the 8x8 dot on the left edge */
}

/* right resizer */
.fc-ltr .fc-h-event.fc-selected .fc-end-resizer,
.fc-rtl .fc-h-event.fc-selected .fc-start-resizer {
	margin-right: -4px; /* centers the 8x8 dot on the right edge */
}


/* DayGrid events
----------------------------------------------------------------------------------------------------
We use the full "fc-day-grid-event" class instead of using descendants because the event won't
be a descendant of the grid when it is being dragged.
*/

.fc-day-grid-event {
	margin: 1px 2px 0; /* spacing between events and edges */
	padding: 0 1px;
}

tr:first-child > td > .fc-day-grid-event {
	margin-top: 2px; /* a little bit more space before the first event */
}
.fc-mirror-skeleton tr:first-child > td > .fc-day-grid-event {
	margin-top: 0; /* except for mirror skeleton */
}

.fc-day-grid-event .fc-content { /* force events to be one-line tall */
	white-space: nowrap;
	overflow: hidden;
}

.fc-day-grid-event .fc-time {
	font-weight: bold;
}

/* resizer (cursor devices) */

/* left resizer  */
.fc-ltr .fc-day-grid-event.fc-allow-mouse-resize .fc-start-resizer,
.fc-rtl .fc-day-grid-event.fc-allow-mouse-resize .fc-end-resizer {
	margin-left: -2px; /* to the day cell's edge */
}

/* right resizer */
.fc-ltr .fc-day-grid-event.fc-allow-mouse-resize .fc-end-resizer,
.fc-rtl .fc-day-grid-event.fc-allow-mouse-resize .fc-start-resizer {
	margin-right: -2px; /* to the day cell's edge */
}


/* Event Limiting
--------------------------------------------------------------------------------------------------*/

/* "more" link that represents hidden events */

a.fc-more {
	margin: 1px 3px;
	font-size: .85em;
	cursor: pointer;
	text-decoration: none;
}

a.fc-more:hover {
	text-decoration: underline;
}

.fc-limited { /* rows and cells that are hidden because of a "more" link */
	display: none;
}

/* popover that appears when "more" link is clicked */

.fc-day-grid .fc-row {
	z-index: 1; /* make the "more" popover one higher than this */
}

.fc-more-popover {
	z-index: 2;
	width: 220px;
}

.fc-more-popover .fc-event-container {
	padding: 10px;
}


/* Now Indicator
--------------------------------------------------------------------------------------------------*/

.fc-now-indicator {
	position: absolute;
	border: 0 solid var(--now-indicator);
}


/* Utilities
--------------------------------------------------------------------------------------------------*/

.fc-unselectable {
	user-select: none;
	-webkit-touch-callout: none;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
