.login-view {
	--overpic-bg: linear-gradient(to bottom, rgba(0,0,0,.25) 0%, transparent 15%, transparent 100%);

	height: 100%; overflow: auto;
	background: var(--back-bg) center / cover no-repeat;

	@mixin login-bg($class) {
		@include media('<=small') {
			&.bg-#{$class} {
				background-image: var(--overpic-bg),
								  url(../img/bg/bg-min-#{$class}.jpg),
								  url(../img/bg/bg-min-low-#{$class}.jpg);
			}
		}
		@include media('>small') {
			&.bg-#{$class} {
				background-image: var(--overpic-bg),
								  url(../img/bg/bg-#{$class}.jpg),
								  url(../img/bg/bg-low-#{$class}.jpg);
			}
		}
	}

	@for $i from 1 through 7 {
		@include login-bg($i);
	}

	@for $i from 1 through 3 {
		@include login-bg('xmas-' + $i);
	}

	@each $date in ('appel', 'new-year', 'starwars', 'halloween') {
		@include login-bg($date);
	}

	&-nav {
		display: flex; padding: $global-gutters;
		flex-flow: row wrap; justify-content: space-between; align-items: flex-end;
		color: var(--login-nav-color);

		&-item {
			display: inline-block; margin: 0 .5em;
		}

		a {
			color: inherit; text-decoration: none;

			&:hover,
			&:focus {
				text-decoration: underline;
			}
		}
	}

	&-main {
		max-width: 35em; margin: 0 auto; padding: 5% $global-gutters $global-gutters; box-sizing: border-box;

		&-inner {
			position: relative;
		}
	}
}

.account-list {
	@extend %ul-reset;
	display: flex; margin: -.25em;
	flex-flow: row wrap; justify-content: space-between; align-items: center;

	li {
		margin: .25em;
		flex: 1 0 33.3%;
	}

	&.is-3-cols li {
		flex-basis: 25%;
	}
}

.login-loading {
	font-size: 6em;
}

.version {
	position: absolute; bottom: 0; right: .5em;
	color: var(--login-nav-color);
	opacity: .5;
}
