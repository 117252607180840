.skin-#{$skin-name} {

	.l-header {
		--header-bg                            : #{$theme-1};
		--header-border                        : none;
		--header-color                         : inherit;
		--header-btn-hover-bg                  : #{$theme-3};
		--header-btn-hover-color               : inherit;
		--header-btn-active-bg                 : var(--primary-color);
		--header-btn-active-color              : #{$white};
		--header-nav-backdrop                  : #{rgba($black, .75)};
	}

	.l-navigation {
		--navigation-bg                        : #{$theme-2};
		--navigation-border                    : none;
		--navigation-hr-bg                     : #{$theme-1};

		--navigation-item-color                : inherit;
		--navigation-item-hover-bg             : #{$theme-3};
		--navigation-item-hover-color          : var(--primary-color);
		--navigation-item-active-bg            : #{$theme-1};
		--navigation-item-active-color         : #{$black};
		--navigation-item-active-border        : #{$theme-3}; // --aside-bg
		--navigation-item-active-border-alt    : #{$theme-3}; // --aside-bg-alt

		--navigation-switch-color              : #{lighten($text-color, 20%)};
		--navigation-switch-bg                 : #{$theme-1};
		--navigation-switch-hover-color        : var(--primary-color);
		--navigation-switch-hover-bg           : #{$theme-3};

		--navigation-account-color             : #{$white};
		--navigation-account-bg                : var(--primary-color);
		--navigation-account-hover-color       : var(--primary-color);
		--navigation-account-hover-bg          : #{$white};
		--navigation-account-active-color      : #{$black};
		--navigation-account-active-bg         : #{$white};

		--navigation-logout-color              : inherit;
		--navigation-logout-bg                 : #{$theme-1};
		--navigation-logout-hover-color        : #{$white};
		--navigation-logout-hover-bg           : #{darken($danger-color, 20%)};
	}

	.l-aside {
		--aside-bg                             : #{$theme-3};
		--aside-sticky-bg                      : #{rgba($theme-3, .75)};
		--aside-border                         : 1px solid var(--default-border-color);
		--aside-hr-bg                          : var(--default-border-color);
		--aside-hr-alt-bg                      : #{rgba($text-color, .5)};
		--aside-color                          : inherit;

		--aside-bg-alt                         : #{$theme-3};
		--aside-border-alt                     : 1px solid var(--default-border-color);
		--aside-hr-bg-alt                      : var(--default-border-color);
		--aside-color-alt                      : inherit;

		--aside-search-color                   : var(--input-color);
		--aside-search-placeholder-color       : var(--input-placeholder-color);
		--aside-search-border-color            : var(--input-border-color);
		--aside-search-shadow-color            : var(--input-shadow-color);
		--aside-search-bg                      : var(--input-bg);

		--aside-search-focus-color             : var(--input-focus-color);
		--aside-search-focus-placeholder-color : var(--input-focus-placeholder-color);
		--aside-search-focus-border-color      : var(--input-focus-border-color);
		--aside-search-focus-shadow-color      : var(--input-focus-shadow-color);
		--aside-search-focus-bg                : var(--input-focus-bg);

		--search-filter-border-color           : transparent;
		--search-filter-bg                     : #{$theme-2};
		--search-filter-item-color             : inherit;
	}

	.aside-search-input::-webkit-search-cancel-button {
		background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 320 512' fill='#{$text-color}'><path d='M207.6 256l107.72-107.72c6.23-6.23 6.23-16.34 0-22.58l-25.03-25.03c-6.23-6.23-16.34-6.23-22.58 0L160 208.4 52.28 100.68c-6.23-6.23-16.34-6.23-22.58 0L4.68 125.7c-6.23 6.23-6.23 16.34 0 22.58L112.4 256 4.68 363.72c-6.23 6.23-6.23 16.34 0 22.58l25.03 25.03c6.23 6.23 16.34 6.23 22.58 0L160 303.6l107.72 107.72c6.23 6.23 16.34 6.23 22.58 0l25.03-25.03c6.23-6.23 6.23-16.34 0-22.58L207.6 256z'/></svg>");
	}

	.l-breadcrumb {
		--breadcrumb-border                    : 1px solid var(--default-border-color);
		--breadcrumb-btn-hover-color           : var(--primary-color);
		--breadcrumb-btn-hover-bg              : #{$theme-2};

		--breadcrumb-btn-color-alt             : #{$white};
		--breadcrumb-btn-bg-alt                : var(--primary-color);
		--breadcrumb-btn-hover-color-alt       : #{$white};
		--breadcrumb-btn-hover-bg-alt          : #{darken($primary-color, 5%)};
	}

	.l-context {
		--context-border                       : 1px solid var(--default-border-color);
		--context-bg                           : #{$theme-3};
		--context-bg-rwd                       : #{rgba($theme-3, .95)};
		--context-sticky-bg                    : #{rgba($theme-3, .75)};

		--search-filter-border-color           : var(--default-border-color);
		--search-filter-bg                     : var(--default-bg);
		--search-filter-item-color             : var(--primary-color);
	}

	.context-search-input::-webkit-search-cancel-button {
		background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 320 512' fill='#{$primary-color}'><path d='M207.6 256l107.72-107.72c6.23-6.23 6.23-16.34 0-22.58l-25.03-25.03c-6.23-6.23-16.34-6.23-22.58 0L160 208.4 52.28 100.68c-6.23-6.23-16.34-6.23-22.58 0L4.68 125.7c-6.23 6.23-6.23 16.34 0 22.58L112.4 256 4.68 363.72c-6.23 6.23-6.23 16.34 0 22.58l25.03 25.03c6.23 6.23 16.34 6.23 22.58 0L160 303.6l107.72 107.72c6.23 6.23 16.34 6.23 22.58 0l25.03-25.03c6.23-6.23 6.23-16.34 0-22.58L207.6 256z'/></svg>");
	}

}
