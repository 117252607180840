.label {
	position: relative;
	display: block; padding: .25em 0;
	font-size: .875em; font-weight: 700; color: var(--label-color);

	&::before {
		position: absolute; width: 100%; z-index: 0;
		transform: translateY(-50%);
		color: var(--label-focus-color);
		opacity: 0; content: attr(data-text); pointer-events: none;
	}

	span {
		position: relative; z-index: 1;
		display: inline-block;
	}

	&::before,
	span {
		transition: $form-duration easing(easeInCubic), $default-duration color;
	}
}

:root {
	--input-padding-v: 1em;
	--input-padding-h: 1em;
}

input,
.input-like,
select,
.select-like,
.togglebox-item,
textarea {
	width: 100%; padding: var(--input-padding-v) var(--input-padding-h); box-sizing: border-box;
	border: 1px solid var(--input-border-color); box-shadow: 0 0 0 .125em var(--input-shadow-color); border-radius: var(--global-border-radius); outline: none;
	background: var(--input-bg);
	font: var(--input-font-style) var(--input-font-weight) 1em/#{$default-line-height} var(--input-font-family); color: var(--input-color); text-align: left;

	&::placeholder {
		color: var(--input-placeholder-color);
		transition: $form-duration ease-out;
	}

	&:hover {
		border-color: var(--input-focus-border-color);
	}

	&:focus {
		@extend %input-focus;
	}

	&:disabled,
	&[disabled],
	&[aria-disabled='true'],
	&.is-disabled {
		@extend %input-disabled;
	}

	&.is-sized {
		width: auto;
	}

	@each $orientation in ('v', 'h') {
		&.is-narrow-padding-#{$orientation} {
			--input-padding-#{$orientation}: .25em;
		}
	}
}

// This is in order to be extendable into .togglebox-item:focus
%input-focus {
	border-color: var(--input-focus-border-color); box-shadow: 0 0 0 .125em var(--input-focus-shadow-color);
	background: var(--input-focus-bg);
	color: var(--input-focus-color);

	&::placeholder {
		color: var(--input-focus-placeholder-color);
	}
}

// This is in order to be extendable into other form components
%input-disabled {
	opacity: .25; cursor: not-allowed; user-select: none;
}

input[type='checkbox'],
input[type='radio'] {
	width: auto; padding: initial; box-sizing: initial;
	border: none; box-shadow: none; border-radius: 0; outline: initial;
	background: none;

	&:focus {
		box-shadow: none;
	}
}

input[type="search"] {
	-webkit-appearance: searchfield;
}

.input-like {
	&.is-selected {
		background-color: var(--input-selected-bg);

		&,
		&:hover,
		&:focus {
			border-color: var(--input-selected-border-color);
			color: var(--input-selected-color);
		}

		&:hover,
		&:focus {
			background-color: var(--input-selected-focus-bg);
		}
	}
}

.select-like {
	position: relative;
	display: flex; padding-right: 2em; overflow: hidden;
	flex-flow: row nowrap; justify-content: flex-start; align-items: center;

	svg#{&}-label {
		font-size: 1.375em;
	}

	&-icon {
		margin-right: .5em;
		flex: 0 0 auto;
	}

	&-caret {
		position: absolute; top: 50%; right: .5em;
		width: 1em; height: 1em;
		transform: translateY(-50%);
		color: var(--select-caret-color);
		transition: inherit;
	}

	&:hover {
		.select-like-caret {
			color: var(--input-focus-border-color);
		}
	}

	&.is-active {
		.select-like-caret {
			transform: translateY(-50%) rotate(180deg);
			color: var(--input-focus-border-color);
		}
	}
}

.togglebox {
	position: relative;

	input {
		@extend %hide-input;
	}

	&-item {
		display: block; padding-left: .5em; padding-right: .5em; overflow: hidden;
		white-space: nowrap; text-overflow: ellipsis; text-align: center;
		cursor: pointer;
		transition: $default-duration $default-timing-function, margin 0s;

		&-icon + &-text {
			margin-left: .25em;
		}
	}

	& input:focus + &-item {
		@extend %input-focus;
	}

	& input:focus + &-item,
	&-item:hover {
		background-color: var(--togglebox-hover-bg);
		color: var(--togglebox-hover-color);
	}

	& input:checked + &-item {
		border-color: var(--togglebox-active-bg);
		background-color: var(--togglebox-active-bg);
		color: var(--togglebox-active-color);
	}
}

.toggledate {
	display: flex;
	flex-flow: row nowrap; align-content: stretch;

	& > * {
		flex: 1 1 auto;
	}

	[type='number'] {
		width: auto; min-width: 0; margin-right: -1px;
		border-top-right-radius: 0; border-bottom-right-radius: 0;

		&:hover {
			z-index: 1;
		}

		&:focus {
			z-index: 2;
		}
	}

	.togglebox-item {
		overflow: initial;

		@include media('>small') {
			padding-left: var(--input-padding-h); padding-right: var(--input-padding-h);
		}

		&:first-of-type {
			border-top-left-radius: 0; border-bottom-left-radius: 0;
		}
	}
}

.textarea-container {
	position: relative;
	display: flex; // Because of weird space at the bottom

	.expand-btn {
		position: absolute; left: 1px; top: 1px; // same as border-width
		margin-top: .75em; padding: .25em;
		opacity: .5;
	}

	&:hover,
	&:focus-within {
		.expand-btn {
			opacity: 1;
		}
	}

	textarea {
		padding-left: 1.5em;
	}
}

.checkbox,
.switchbox,
.radio {
	position: relative;
	display: inline-block;
	vertical-align: middle;

	label {
		&:disabled,
		&[disabled],
		&[aria-disabled='true'],
		&.is-disabled {
			@extend %input-disabled;
		}
	}

	& + label {
		vertical-align: middle;
	}
}

.checkbox,
.radio {
	label {
		position: relative;
		display: block; width: $default-line-height * 1em; height: $default-line-height * 1em; margin: 0;
		border: 1px solid var(--input-border-color); box-shadow: 0 0 0 .125em var(--input-shadow-color); outline: none;
		background-color: var(--input-bg);
		cursor: pointer;
		transition: $default-duration $default-timing-function;
	}

	.input-like & {
		display: block;

		label {
			margin: -.075em;
		}
	}
}

.checkbox [type='checkbox'],
.switchbox [type='checkbox'],
.radio [type='radio'] {
	@extend %hide-input;
}

.checkbox  {
	label {
		border-radius: var(--global-border-radius);
	}

	& + label:empty {
		display: none;
	}

	.mark {
		position: absolute; bottom: .125em; left: 0;
		width: 1.6em; height: 1.2em;
		background: none;
		fill: none; stroke: var(--primary-color); stroke-width: 1.5; stroke-linecap: round; stroke-dasharray: 8.5, 9; stroke-dashoffset: 9;
		pointer-events: none;
		transition: inherit;
	}

	[type='checkbox']:checked + label .mark {
		stroke-dashoffset: 0;
	}
}

.radio {
	label {
		display: flex;
		border-radius: 50%;

		&::after {
			display: block; width: .75em; height: .75em; margin: auto;
			border-radius: 50%;
			background: var(--primary-color);
			transform: scale(0);
			content: '';
			transition: inherit;
		}
	}

	[type='radio']:checked + label::after {
		transform: scale(1);
	}
}

.switchbox {
	label {
		position: relative;
		display: block; width: 4em; height: 2em; padding: .125em;
		border: 1px solid var(--input-border-color); box-shadow: 0 0 0 .125em var(--input-shadow-color); border-radius: 1.25em; outline: none;
		background: var(--input-bg);
		cursor: pointer;
		transition: $default-duration $default-timing-function;

		&::before {
			position: absolute; top: 50%; left: .125em; z-index: 1;
			width: 2em; height: 2em; box-sizing: border-box;
			transform: translateY(-50%);
			border: 1px solid var(--input-border-color); border-radius: 50%;
			background: var(--switchbox-bullet-bg);
			content: '';
			transition: inherit;
		}
	}

	.switch-yes,
	.switch-no {
		position: absolute; top: 50%;
		transform: translateY(-50%);

	}

	.switch-yes {
		left: .625em;
		color: var(--switchbox-checked-color);
	}

	.switch-no {
		right: .625em;
		color: var(--input-color);
	}

	[type='checkbox']:checked + label {
		background: var(--primary-color);

		&::before {
			transform: translateY(-50%) translateX(2em);
			border-color: var(--primary-color);
			background: var(--switchbox-checked-bullet-bg);
		}
	}

	&.is-danger {
		[type='checkbox']:checked + label {
			--input-border-color: var(--danger-color);
			--input-focus-border-color: var(--danger-color);
			--input-shadow-color: var(--input-alert-shadow-color);
			--input-focus-shadow-color: var(--input-alert-focus-shadow-color);
			background: var(--danger-color);

			&::before {
				border-color: var(--danger-color);
			}
		}
	}
}

.checkbox [type='checkbox'] + label:hover,
.switchbox [type='checkbox'] + label:hover,
.radio [type='radio'] + label:hover {
	border-color: var(--input-focus-border-color);
}

.checkbox [type='checkbox']:focus + label,
.switchbox [type='checkbox']:focus + label,
.radio [type='radio']:focus + label {
	border-color: var(--input-focus-border-color); box-shadow: 0 0 0 .125em var(--input-focus-shadow-color);
}

.input-color {
	--value: var(--input-bg);

	@extend .input-like;
	display: block;
	background-color: var(--value);
	cursor: pointer;
	transition: $form-duration $default-timing-function, margin 0s;

	&.is-light {
		color: white;
	}

	&.is-dark {
		color: black;
	}

	input[type='color'] {
		@extend %hide-input;
		width: auto;
	}
}

/*
fieldset,
.fieldset-like {

}

legend, .legend {

}
*/

.input-frame,
.input-frame-like {
	margin: .5em 0;

	&.is-alert {
		--label-color: var(--danger-color);
		--label-focus-color: var(--danger-color);
		--input-border-color: var(--danger-color);
		--input-focus-border-color: var(--danger-color);
		--input-shadow-color: var(--input-alert-shadow-color);
		--input-focus-shadow-color: var(--input-alert-focus-shadow-color);
		--select-caret-color: var(--danger-color);
	}
}

.input-frame {
	&:focus-within .label {

		&::before {
			transform: translateY(0);
			opacity: 1;
		}

		span {
			transform: translateY(50%);
			opacity: 0;
		}
	}
}

.input-frame-like {
	&::before {
		// Same as .label
		display: block; padding: .25em 0;
		font-size: .875em;
		content: '\00a0';
	}

	.misc-item {
		margin: 0;
	}
}

.with-icon {
	position: relative;

	input {
		padding-left: 2.5em;
	}

	&-icon {
		position: absolute; top: 50%; left: .5em; z-index: 3;
		width: 1em; height: 1em;
		transform: translateY(-50%);
		font-size: 1.25em;
		opacity: 0; pointer-events: none;
		transition: opacity .25s $default-timing-function;

		&.is-shown {
			opacity: 1;
		}
	}
}

.msg-alert {
	overflow: hidden;

	&-inner {
		padding: .25em .5em;
		transform: translateY(-100%);
		background-color: var(--alert-feedback-bg);
		color: var(--danger-color); font-size: .875em; font-weight: var(--font-weight-bold);
		animation: $default-duration forwards slide-in;
	}
}

.form-group {
	display: flex;
	flex-flow: row nowrap; justify-content: flex-start; align-items: stretch;

	.btn {
		margin-left: 0; margin-right: 0;
	}

	& > * {
		flex: 1 1 auto;

		&:not(:last-child) {
			margin-right: -1px; // border width
		}

		&:hover {
			&:not(.is-active) {
				z-index: 1;
			}
		}

		&:focus,
		&:focus-within {
			&:not(.is-active) {
				z-index: 2;
			}
		}
	}

	& > * &-input {
		height: 100%; // For non-direct childs, i.e. dropdown trigger
	}

	& > .is-flex-fixed {
		width: auto; // Button or input case
		flex: 0 0 auto;
	}

	& > *:not(:first-child) &-input,
	& > &-input:not(:first-child):not(.is-first) { // Need to specify sometimes
		border-top-left-radius: 0; border-bottom-left-radius: 0;
	}

	& > *:not(:last-child) &-input,
	& > &-input:not(:last-child):not(.is-last) { // Need to specify sometimes
		border-top-right-radius: 0; border-bottom-right-radius: 0;
	}
}
