.dashboard {
	@include grid(3, 3, 180rem);

	@include media('<=small') {
		.grid-3 {
			grid-template-columns: 1fr;
		}
	}

	@include media('>small') {
		.grid-3 {
			--size: 20rem;
		}
	}

	@include media('>medium') {
		.grid-3 {
			--size: 30rem;
		}
	}

	@include media('>large') {
		.grid-3 {
			--size: 43rem;
		}
	}

	&-widget {
		display: flex; overflow: hidden;
		align-self: start;
		flex-flow: column nowrap; justify-content: flex-start; align-items: stretch;
		border: 1px solid var(--input-border-color); border-radius: var(--global-border-radius);
		background-color: var(--input-bg);

		&-header {
			margin: 0; padding: .5em $global-gutters;
		}

		&-subheader {
			padding-left: $global-gutters; padding-right: $global-gutters;
			border-bottom: 1px solid var(--hr-bg);
			background-color: var(--input-bg);
			flex-shrink: 0;
		}

		&-expand {
			&-icon {
				transition: inherit;
			}

			&-btn.is-expanded &-icon {
				transform: rotate(180deg);
			}
		}

		&-content {
			display: flex; min-height: 30vh; overflow: auto;
			flex-flow: column nowrap; align-items: stretch;
			border-top: 1px solid var(--input-border-color);
			transition: .5s ease-out;
		}

		&:not(.is-expanded) &-content {
			max-height: 30vh;
		}

		&-list {
			li {
				display: flex; padding: .5em $global-gutters;

				& + li {
					border-top: 1px solid var(--default-border-color);
				}

				&:nth-child(even) {
					background-color: var(--default-bg);
				}
			}
		}

		&-link {
			display: inline-flex;
			flex-flow: row nowrap; justify-content: flex-start; align-items: center;

			&-avatar {
				margin-right: .5em;
				flex: 0 0 auto;
			}
		}

		&-full-height {
			display: flex; padding: 0 $global-gutters;
			flex: 1 0 auto;
			justify-content: center; align-items: center;
		}

		&-with-padding {
			padding-left: $global-gutters; padding-right: $global-gutters;
		}

		&-summary {
			display: flex; width: 100%;
			flex-flow: row wrap; justify-content: space-around; align-items: flex-end;

			& > * {
				padding: .5em; box-sizing: border-box;
				flex: 1 1 8em;
				text-align: center;
			}

			&-number {
				font-size: 4em; line-height: 1; color: var(--primary-color);
			}
		}
	}
}
