.fc-toolbar.fc-header-toolbar {
	margin-bottom: 0;
}

.fc-view {
	// popover need to be over stycky toolbar
	// but fc-view create a new stacking context, so…
	z-index: unset;
}

.fc-more-popover {
	// popover need to be over stycky toolbar
	z-index: $z-index-sticky + 1;
}

.fc-event {
	padding: 2px;
	border-radius: 0;
	border: 1px solid;
	border-color: rgba(black, .25) !important; // Override inline style
	background-color: var(--color, --event-bg) !important; // Override inline style
	font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
	line-height: 1.2;
	transition-timing-function: easing(easeOutExpo);

	&.fc-dragging {
		transition: none;
	}

	&.is-done:not(.is-unvalid):not(.is-missed) {
		border-color: var(--color) !important; // Override inline style
		color: inherit !important; // Override inline style

		&::before {
			content: '';
			position: absolute; top: 0; right: 0; bottom: 0; left: 0; z-index: 0;
			background-color: var(--default-bg);
			opacity: .8;
		}

		& > *:not(.fc-resizer) {
			position: relative; z-index: 1;
		}
	}

	&.is-unvalid {
		padding: 0;
		border-width: 3px; border-color: var(--color) !important; // Override inline style
		background-color: var(--default-bg) !important;
		color: inherit !important; font-weight: bold;
	}

	&.is-missed {
		border-color: var(--color) !important; // Override inline style
		background-color: var(--default-bg) !important;
		color: inherit !important; text-decoration: line-through;
		opacity: .5;

		&:hover,
		&:focus {
			opacity: 1;
		}
	}
}

.fc-event-dot {
	background-color: var(--color, --event-bg) !important; // Override inline style
}

.fc-dayGrid-view .fc-day-top .fc-week-number {
	background-color: transparent;
	color: inherit;
}

.fc-widget-header-number {
	display: block;
	font-size: 1.5em;
}


.fc-dayGridMonth-view .fc-week-number  {
	width: 3em !important;
}

.fc-dayGridMonth-view td.fc-week-number > span {
	font-size: 1.25em; line-height: 1.2;
}

.fc-timeGridWeek-view .fc-axis {
	width: 4em !important;
}

.fc-ltr .fc-dayGrid-view .fc-day-top .fc-day-number {
	font-size: 1.25em;
	line-height: 1.2;
}

.fc-time-grid .fc-now-indicator-line {
	border-bottom-width: 1px;
	pointer-events: none;
}
