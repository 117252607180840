.l-app {
	--app-default-transition: background-color #{$panel-duration} #{$default-timing-function};

	box-shadow: 0 0 .5em rgba(black, .75);
	background-color: var(--default-bg);
	transition: var(--app-default-transition); // For skin switching

	@include media('>medium') {
		@supports (filter: blur(5px)) {
			will-change: filter;
			transition: filter #{$popin-duration / 2} $popin-timing-function,
			            var(--app-default-transition);
		}
	}
}
