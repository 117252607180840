.l-breadcrumb {
	display: flex;
	flex-flow: row nowrap; justify-content: space-between; align-items: center;
	border-bottom: var(--breadcrumb-border);
	transition: border-bottom-color $panel-duration $default-timing-function; // For skin switching
}

.breadcrumb {
	display: flex; min-width: 0; margin: .5em $global-gutters; overflow: hidden;
	flex-flow: row nowrap; justify-content: flex-start; align-items: center;

	& > * {
		margin-right: .25em;
	}

	&-icon {
		flex: 0 0 auto;
	}

	&-item {
		min-width: 0;
		flex: 0 1 auto;
	}
}

.top-ctrl {
	display: flex;
	flex-flow: row nowrap; justify-content: flex-end; align-items: center;
	border-right: var(--breadcrumb-border);
	transition: border-left-color $panel-duration $default-timing-function; // For skin switching

	&.is-pushed-right {
		border-right: 0; border-left: var(--breadcrumb-border);
	}
}

.btn-in-breadcrumb {
	@extend .btn--reset;
	display: inline-flex;
	flex-flow: row nowrap; justify-content: flex-start; align-items: center;
	font-weight: var(--font-weight-bold);

	@include media('<=large') {
		padding: .75em;

		&-icon {
			font-size: 1.25em;
		}
	}

	@include media('>large') {
		padding: .625em 1em;

		&-icon {
			margin-right: .5em;
		}
	}

	&:hover,
	&:focus {
		background-color: var(--breadcrumb-btn-hover-bg);
		color: var(--breadcrumb-btn-hover-color);
	}

	&.is-for-aside {
		.is-without-aside & {
			display: none;
		}
	}

	&.is-for-context {
		.is-for-less {
			display: none;
		}

		&:not(.is-open) {
			background-color: var(--breadcrumb-btn-bg-alt);
			color: var(--breadcrumb-btn-color-alt);

			&:hover,
			&:focus {
				background-color: var(--breadcrumb-btn-hover-bg-alt);
				color: var(--breadcrumb-btn-hover-color-alt);
			}
		}

		&.is-open {
			.btn-in-breadcrumb-icon {
				transform: rotate(-180deg);
			}
			.is-for-less {
				display: initial;
			}
			.is-for-more {
				display: none;
			}
		}
	}

	&.is-with-sub-nav {
		.btn-in-breadcrumb-icon {
			&-search {
				display: none;
			}

			&-nav {
				display: block;
			}
		}
	}

	&-icon {
		transition: transform $default-duration $default-timing-function, color 0s;

		&-search {
			display: block;
		}

		&-nav {
			display: none;
		}
	}
}
