.logo {
	width: 7.61em;
}

.avatar {
	display: block; width: 1em; min-width: 1em; // In case of <img>

	&-bg {
		transition: fill $default-duration $default-timing-function;
	}

	&-text {
		font-family: Consolas, monaco, monospace; font-size: 12px;
		transition: color $default-duration $default-timing-function;

		&.is-dark {
			color: rgba(black, .5);
		}

		&.is-light {
			color: rgba(white, .75);
		}
	}
}

.svg-middle {
	vertical-align: -.125em;
}

.svg-block {
	display: block;
}

.svg-center {
	margin-left: auto; margin-right: auto;
}

.svg-flag {
	width: 1.33em;
	fill: black;
}

.svg-xl {
	font-size: 4em;
}
