/* ==========================================================================
   Base styles: opinionated defaults
   ========================================================================== */

html {
	text-rendering: optimizeLegibility;
	text-size-adjust: 100%;
	font-kerning: auto;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

body {
	background-color: var(--body-bg);
}

::selection {
	background-color: var(--selection-bg);
	color: var(--selection-color); text-shadow: none;
}

hr {
	height: 1px; padding: 0;
	border: 0;
	background-color: var(--hr-bg);
}

audio, canvas, iframe, img, video {
	vertical-align: middle;
}

dialog {
	position: static;
	margin: auto; padding: 0;
	border: none;
	color: inherit;
}

fieldset {
	margin: 0; padding: 0;
	border: 0;
}

a, button, .btn--link-like {
	transition: $default-duration $default-timing-function, margin 0s;
}

input, select {
	transition: $form-duration $default-timing-function, margin 0s;
}

textarea {
	resize: none; // to avoid specific behavior with autoresize in textarea
	transition: $default-duration $default-timing-function, height 0s;
}

button, .btn--link-like {
	color: inherit;
	cursor: pointer;
}

ul {
	padding-left: 1em;
}

abbr[title] {
	text-decoration: none;
}

figure {
	margin: 0;
}

img {
	/* width: auto; */ max-width: 100%; height: auto;
}

svg {
	display: inline-block; width: 1em; height: 1em;
	fill: currentColor;
	vertical-align: baseline;
}

a svg,
button svg {
	text-decoration: none;
	pointer-events: none;
}

mark {
	background-color: yellow;
	color: black;
}

kbd {
	display: inline-block; padding: 0 .5em;
	border: solid 1px var(--input-border-color); border-radius: .25em; box-shadow: 0 1px 0 var(--input-border-color), 0 2px .25em rgba(black, .1);
	background: var(--default-bg) linear-gradient(rgba(black, .05), transparent);
}
