.marquee {
	overflow: hidden;
	white-space: nowrap;

	&-inner {
		display: inline-block; min-width: 100%;
	}

	&:hover {
		.marquee-inner,
		.marquee-content {
			animation: 3s ease-in-out infinite alternate;
		}
		.marquee-inner {
			animation-name: marquee-inner;
		}
		.marquee-content {
			animation-name: marquee-content;
		}
	}
}

@keyframes marquee-inner {
	to { margin-left: 100%; }
}

@keyframes marquee-content {
	to { margin-left: -100%; }
}
