@font-face {
	font-family: 'Comfortaa';
	font-style: normal;
	font-weight: 400;
	font-display: swap;
	src: local('Comfortaa Regular'),
	     local('Comfortaa-Regular'),
	     url('../../../fonts/comfortaa-regular.woff2') format('woff2');
}

@font-face {
	font-family: 'Comfortaa';
	font-style: normal;
	font-weight: 700;
	font-display: swap;
	src: local('Comfortaa Bold'),
	     local('Comfortaa-Bold'),
	     url('../../../fonts/comfortaa-bold.woff2') format('woff2');
}
