.stat-list {
	--cols: 1;

	display: grid;
	grid-template-columns: repeat(var(--cols), 1fr);
	grid-gap: $global-gutters; grid-auto-flow: row dense; justify-items: stretch; align-items: stretch;

	@include media('>small') {
		--cols: 2;
	}

	@include media('>xlarge') {
		--cols: 3;
	}

	&-value {
		font-size: 4.5em; font-weight: var(--font-weight-bold);
	}
}
