.news {
	&-figure {
		position: relative;
		height: 0; padding-bottom: 35%; overflow: hidden;
		border-radius: var(--global-border-radius);
		background-color: var(--default-bg);
	}

	&-image {
		position: absolute; top: 0; right: 0; bottom: 0; left: 0;
		width: 100%; height: 100%;
		object-fit: cover;
	}

	&-date {
		position: absolute; bottom: 0; left: 0; right: 0; z-index: 1;
		padding: .125em .25em;
		background-color: rgba(black, .5);
		color: white;
	}
}
