@font-face {
	font-family: 'Roboto Mono';
	font-style: normal;
	font-weight: 400;
	font-display: swap;
	src: local('Roboto Mono'),
	     local('RobotoMono-Regular'),
	     url('../fonts/roboto-mono-regular.woff2') format('woff2');
}

@font-face {
	font-family: 'Permanent Marker';
	font-style: normal;
	font-weight: 400;
	font-display: swap;
	src: local('Permanent Marker Regular'),
	     local('PermanentMarker-Regular'),
	     url('../fonts/permanent-marker-regular.woff2') format('woff2');
}
