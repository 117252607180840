.l-aside {
	@include media('>small', '<=medium') {
		padding-top: .5em;
	}

	&.l-aside-search {
		border-right: var(--aside-border);
		background-color: var(--aside-bg);
		color: var(--aside-color);

		a,
		.btn--link-like {
			color: inherit;
		}

		hr {
			background-color: var(--aside-hr-bg);
		}
	}

	&.l-aside-nav {
		display: flex;
		flex-flow: column nowrap; justify-content: flex-start; align-items: stretch;
		border-right: var(--aside-border-alt);
		background-color: var(--aside-bg-alt);
		color: var(--aside-color-alt);

		hr {
			background-color: var(--aside-hr-bg-alt);
			transition: background-color $panel-duration $default-timing-function; // For skin switching
		}
	}

	&-btn-close {
		@extend .btn--reset;
		position: absolute; top: 0; right: 0; z-index: 6;
		font-size: 2em;
		opacity: .5;

		&:hover,
		&:focus {
			opacity: 1;
		}
	}
}

.aside-navigation {
	@extend .ul-reset;
	margin: $global-gutters 0;

	.hr {
		@extend hr;
		margin: #{$global-gutters / 2} 0;
	}

	button {
		@extend .btn--reset;
	}

	a,
	button,
	.aside-link-like {
		display: block; width: 100%; padding: .75em 1em; box-sizing: border-box;
		background-color: var(--aside-nav-bg);
		color: var(--aside-nav-color); font-weight: var(--font-weight-bold); text-decoration: none;
	}

	a,
	button {
		&.is-delete {
			color: var(--danger-color);

			&:hover,
			&:focus {
				background-color: var(--danger-color);
				color: white;
			}
		}

		&.is-whitelisted {
			&:hover,
			&:focus {
				background-color: black;
				color: white;
			}
		}

		&.is-blacklisted {
			&:hover,
			&:focus {
				background-color: white;
				color: black;
			}
		}
	}

	.aside-link-like {
		&.is-current-step {
			color: var(--primary-color);
		}

		&.is-future-step {
			opacity: .25;
		}
	}

	.with-badge {
		display: flex;
		flex-flow: row nowrap; justify-content: space-between; align-items: center;

		&-label {
			flex: 1 1 auto;
		}

		&-badge {
			--color: var(--primary-color);
			display: flex; min-width: 2em; height: 2em; margin: -.25em 0; padding: 0 .5em; box-sizing: border-box;
			flex: 0 0 auto; flex-flow: row nowrap; justify-content: center; align-items: center;
			border-radius: 1em;
			background-color: var(--color);
			font-size: .875em; font-weight: var(--font-weight-normal); color: white;

			&.is-light {
				color: white;
			}

			&.is-dark {
				color: black;
			}
		}
	}
}
