.skin-#{$skin-name} {

	--font-weight-normal   : 400;
	--font-weight-bold     : 700;

	--default-font-family  : "Merriweather", Georgia, serif;
	--default-font-weight  : var(--font-weight-normal);
	--default-font-style   : normal;

	--title-font-family    : "Open Sans", arial, sans-serif;
	--title-font-weight    : 600;
	--title-font-style     : normal;

	--input-font-family    : "Merriweather", Georgia, serif;
	--input-font-weight    : var(--font-weight-normal);
	--input-font-style     : normal;

	--button-font-family   : "Open Sans", arial, sans-serif;
	--button-font-weight   : 600;
	--button-font-style    : normal;

}
