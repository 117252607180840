.scrollbar {
	// https://css-tricks.com/custom-scrollbars-in-webkit/

	/*
	$scrollbar-size  : .5rem;
	$scrollbar-margin: .25rem;
	*/

	// W3C, Firefox
	scrollbar-width: thin;
	scrollbar-color: var(--scrollbar-thumb) var(--scrollbar-track);

	// webkit specific
	&::-webkit-scrollbar {
		width: calc(#{$scrollbar-size} + (#{$scrollbar-margin} * 2));
		height: calc(#{$scrollbar-size} + (#{$scrollbar-margin} * 2));
	}

	&::-webkit-scrollbar-thumb {
		border: $scrollbar-margin solid transparent; // Margin
		background-clip: padding-box; // Booyah!
		border-radius: calc((#{$scrollbar-size} + (#{$scrollbar-margin} * 2))/2);
		background-color: var(--scrollbar-thumb);
	}

	&::-webkit-scrollbar-track {
		background-color: var(--scrollbar-track);
	}

	&::-webkit-scrollbar-thumb:horizontal,
	&::-webkit-scrollbar-thumb:vertical {
		&:hover {
			background-color: var(--scrollbar-thumb-hover);
		}

		&:active {
			background-color: var(--scrollbar-thumb-active);
		}
	}

	&::-webkit-scrollbar-corner {
		visibility: hidden;
	}
}
