.drawer {
	$drawer-width : 365px;
	$drawer-height: 719px;

	&-toolbar {
		& > * {
			display: inline-flex;
		}
	}

	& &-notice {
		display: none; margin-bottom: 0;
	}

	&-frame {
		position: relative;
		width: $drawer-width; height: $drawer-height; overflow: hidden; box-sizing: border-box;
		flex: 0 0 $drawer-width;
		border: 1px solid var(--default-border-color); border-radius: var(--global-border-radius);
		background-color: white;
		cursor: crosshair;
	}

	&-controls {
		position: absolute; top: -1px; right: -1px; z-index: 3;

		& > *:first-child {
			border-top-left-radius: 0;
		}

		& > *:last-child {
			border-bottom-right-radius: 0;
		}
	}

	&-canvas {
		position: absolute; top: 0; right: 0; bottom: 0; left: 0; z-index: 2;
		background: none;
	}

	&-illustration {
		&-svg {
			display: block; width: auto; height: auto;
		}

		&-backbone {
			position: absolute; top: 0; right: 0; bottom: 0; left: 0; z-index: 1;
		}
	}


	// Media adaptations -----

	@include media('<=small') {
		&-frame {
			cursor: not-allowed;

			&::before {
				position: absolute; top: 0; right: 0; bottom: 0; left: 0; z-index: 3;
				content: '';
			}
		}

		&-toolbar,
		&-controls {
			display: none;
		}

		& &-notice {
			display: block;
		}

		&-canvas {
			pointer-events: none;
		}
	}

	&.is-readonly &-frame {
		cursor: not-allowed;

		&::before {
			position: absolute; top: 0; right: 0; bottom: 0; left: 0; z-index: 4;
			content: '';
		}
	}

	&.is-readonly &-toolbar,
	&.is-readonly &-controls {
		display: none;
	}

	&.is-readonly &-canvas {
		pointer-events: none;
	}
}
