.misc-item {
	position: relative;

	&-input {
		@extend .is-hidden;
	}

	&-content {
		display: flex;
		flex-flow: row nowrap; justify-content: flex-start; align-items: center;
		border: 1px solid var(--input-border-color); box-shadow: 0 0 0 .125em var(--input-shadow-color); border-radius: var(--global-border-radius);
		background-color: var(--input-bg);
		color: var(--input-color);
		cursor: pointer;
		transition: $default-duration $default-timing-function;
	}

	&-icon {
		padding: .25em;
		align-self: stretch;
		border-radius: var(--global-border-radius) 0 0 var(--global-border-radius);
		background-color: var(--misc-item-icon-bg);
		color: inherit;
		transition: inherit; transition-property: background-color;

		svg,
		img,
		&-color {
			display: block; width: 1em; height: 1em;
		}

		svg,
		&-color {
			margin: .25em;
			font-size: 1.5em;
		}

		img {
			border-radius: var(--global-border-radius);
			font-size: 2.25em;
		}

		&-color {
			border-radius: 50%;
			background-color: var(--color);
		}

		.avatar {
			margin: 0;
			font-size: 2.875em;
		}
	}

	&-label {
		padding: .25em .5em;

		a,
		button {
			color: inherit;
		}
	}

	&-input:focus + &-content,
	&-content:hover {
		border-color: var(--input-focus-border-color);
	}

	&-input:focus + &-content {
		box-shadow: 0 0 0 .125em var(--input-focus-shadow-color);
		color: var(--misc-item-hover-color);
	}

	&-input:checked + &-content {
		border-color: var(--input-focus-border-color);
		background-color: var(--misc-item-active-bg);
		color: var(--misc-item-active-color);

		.misc-item-icon {
			background-color: var(--misc-item-active-icon-bg);
			color: var(--misc-item-active-icon-color);
			transition: inherit;
		}
	}
}
