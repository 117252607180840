.masonry {
	$cols: 3;
	$size: ($wrapper-width - ($global-gutters * $cols)) / ($cols + 1) + 0.1rem;

	columns: $cols $size; column-gap: $global-gutters;

	& > * {
		margin-bottom: $global-gutters;
		break-inside: avoid;
	}
}
