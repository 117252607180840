.custom-form {
	&-item {
		border: 1px solid transparent; // For dragging style transition

		& + & {
			margin-top: -1px;
			border-top-color: var(--hr-bg);
		}
	}

	&-no-result {
		margin: 20% 0;
	}
}
