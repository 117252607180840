.dropdown {
	position: relative;
	/*display: block; width: 100%;*/ // Not sure to remove this
	box-sizing: border-box;

	&-toggle {
		overflow: hidden;
	}

	&-label {

	}

	&-caret {
		transition: inherit;
	}

	&-menu {
		@include rwd-rem();
		--max-height: 16em;
		display: flex; // Do some magics with overflow on default state
		opacity: 0; visibility: hidden; pointer-events: none;

		@include media('<=medium') {
			position: fixed; top: 0; right: 0; bottom: 0; left: 0; z-index: $z-index-popin - 1;
			display: flex; padding: 15%; overflow: hidden;
			flex-flow: column nowrap; justify-content: center; align-items: center;
			background-color: var(--default-backdrop-bg);
			transition: $popin-duration $popin-timing-function;
		}

		@include media('>medium') {
			position: absolute; z-index: $z-index-dropdown;
			width: max-content; max-height: 0; margin-top: -1px;
			transition: $default-duration $default-timing-function, max-height 0s $default-duration;

			&:not(.is-on-top) {
				top: 100%;
				transform: translateY(-1em);
			}

			&.is-on-top {
				bottom: 100%;
				transform: translateY(1em);
			}

			&.is-stuck-left {
				left: 0;
			}

			&.is-stuck-right {
				right: 0;
			}

			&.is-stuck-both {
				right: 0; left: 0;
				width: 100%; box-sizing: border-box;
			}
		}

		&-backdrop {
			@extend .btn--reset;

			@include media('<=medium') {
				position: absolute; top: 0; right: 0; bottom: 0; left: 0; z-index: 0;
				display: block; width: 100%; height: 100%;
			}

			@include media('>medium') {
				display: none;
			}
		}

		&-list {
			@extend %ul-reset, .scrollbar;
			width: 100%; box-sizing: border-box; overflow: hidden; overflow-y: auto;
			border-radius: var(--global-border-radius);
			background-color: var(--dropdown-bg);
			color: inherit; line-height: $default-line-height; font-weight: var(--font-weight-normal); text-align: left;

			@include media('<=medium') {
				max-width: 30em; z-index: 1;
				transform: translateY(-1em);
				transition: inherit;
			}

			@include media('>medium') {
				max-height: var(--max-height);
				border: 1px solid var(--dropdown-border-color); box-shadow: 0 .125em .25em var(--default-box-shadow);
			}
		}

		&-item {
			@extend .btn--reset;
			display: flex; width: 100%; box-sizing: border-box; overflow: hidden;
			flex: 1 0 auto;
			flex-flow: row nowrap; justify-content: flex-start; align-items: center;
			background-color: var(--dropdown-item-bg);
			color: var(--dropdown-item-color); text-decoration: none; white-space: nowrap;

			&.is-selected {

			}

			&.is-disabled {
				opacity: .25;
				pointer-events: none;
			}

			&-icon {
				display: flex;
				flex-grow: 0; flex-shrink: 0;
				background-color: var(--dropdown-item-icon-bg);

				@include media('<=medium') {
					width: 3.5em; height: 3.5em;
					flex-basis: 3.5em;
				}

				@include media('>medium') {
					width: 2.5em; height: 2.5em;
					flex-basis: 2.5em;
				}

				& > * {
					margin: auto;
					font-size: 1.25em;
				}
			}

			&-label {
				@extend .text-ellipsis;
				flex: 1 1 auto;

				@include media('<=medium') {
					padding: 1em;
				}

				@include media('>medium') {
					padding: .5em 1em;
				}
			}

			&-value {
				flex: 0 0 auto;
				font-weight: var(--font-weight-bold);

				@include media('<=medium') {
					padding: 1em .5em;
				}

				@include media('>medium') {
					padding: .5em;
				}
			}
		}

		&-hr {
			@extend hr;
			margin: 0;
		}
	}

	&.is-active &-caret {
		transform: rotate(180deg);
	}

	&.is-active &-menu {
		opacity: 1; visibility: visible; pointer-events: auto;

		@include media('<=medium') {
			&-list {
				transform: translateY(0);
			}
		}

		@include media('>medium') {
			max-height: var(--max-height);
			transform: translateY(0);
			transition-delay: 0s, 0s;
		}
	}
}
