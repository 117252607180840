.btn {
	display: inline-block; margin: .25em; padding: .375em 1em; box-sizing: border-box;
	border: 1px solid var(--btn-border-color); box-shadow: 0 .125em 0 var(--btn-border-color); border-radius: var(--global-border-radius);
	background-color: var(--btn-bg);
	color: var(--btn-color); font: var(--button-font-style) var(--button-font-weight) 1em/#{$default-line-height} var(--button-font-family); text-shadow: 0 1px 0 var(--btn-border-color); text-align: center; text-decoration: none;
	vertical-align: baseline;

	&:not(.btn--link-like) {
		&:focus {
			outline: none;
		}
	}

	&:hover,
	&:focus,
	&:active {
		z-index: 1;
	}

	&:active {
		transform: translateY(.125em);
		box-shadow: 0 0 0 transparent;
	}

	&:disabled,
	&[aria-disabled='true'],
	&.is-disabled {
		opacity: .25; cursor: not-allowed; user-select: none; pointer-events: none;
	}

	&:first-child {
		margin-left: 0;
	}

	&:last-child {
		margin-right: 0;
	}
}

.btn--frame {
	width: 100%;
	margin: 0;
	padding: .95em 1em;
}

.btn--reset,
.btn--link-like {
	display: inline; margin: 0; padding: 0;
	border: none; outline: none;
	background: none;
	font: inherit; text-align: inherit;
	vertical-align: baseline; appearance: none;

	&[disabled],
	&[aria-disabled='true'],
	&.is-disabled {
		color: var(--btn-disabled-color);
		opacity: .25; cursor: not-allowed; user-select: none; pointer-events: none;
	}
}

.is-full-width {
	width: 100%;
}

.btn--input-shape {
	width: 100%; margin: 0; padding: 1em;
	box-shadow: none;
}

button,
a {
	&.input-like {
		text-decoration: none;
		&:hover,
		&:focus {
			background-color: var(--togglebox-hover-bg);
			color: var(--togglebox-hover-color);
		}
	}
}

.with-loader {
	position: relative;

	&-text,
	&-spinner {
		transition: inherit; transition-property: opacity;
	}

	&-spinner {
		position: absolute; top: 50%; left: 50%;
		height: 1em;
		transform: translate(-50%, -50%);
		font-size: 1.75em; line-height: 1em;
		opacity: 0;

		.loading-spinner {
			&, & circle {
				animation-play-state: paused;
			}
		}
	}

	&.is-loading {
		pointer-events: none;

		.with-loader-text {
			opacity: 0;
		}

		.with-loader-spinner {
			opacity: 1;

			.loading-spinner {
				&, & circle {
					animation-play-state: running;
				}
			}
		}
	}
}
button.google {
	//background-image: url(~@Assets/img/illustrations/google-connect.jpg);
	//background-image: url(../img/illustrations/google-connect.png);
	//background-image: url(https://i.goopics.net/rykbkx.jpg);
	//background-size:cover;
	border: 0;
	& img {
		width: 28.2rem;
		height: 6.8rem;
	}
}
