.account-avatar {
	position: relative;
	display: inline-block; overflow: hidden;
	border-radius: var(--global-border-radius);

	.avatar {
		font-size: 10em;
	}

	a {
		position: absolute; bottom: 0; left: 0; right: 0;
		padding: .5em 0;
		background-color: rgba(black, .75);
		color: white; text-align: center;
	}
}
